/* eslint-disable */
import React, { useState, useEffect,useRef } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import dayjs from "dayjs";
import _ from "lodash/fp";
import { useForm } from "react-hook-form"; 
import { toastr } from "react-redux-toastr";
import { getMyApplications, getProfileInfoDisplay, setShowApplicationReceipt, setShowApplicationDetails, setChangePasswordFlg, setUpdateProfileflg, setProfileViewFlg ,setPrintProfile, setApplicationDetailsFlg,
    reconcileNow, getPostPreferenceDetails, updateProfileRequest,setAdditonalQuestions, getExamDisplayNameDetailsForAdmitCard, downloadInterviewPTLetterAPI, 
    setChangeCenterModal, setUpdatePostModal, externalApplicationStage, setPaymentHistoryModal, reCheckEligibilityAction, resetApplicationPostPrefListForReCheckEligibility, updateFeesPaidApplication, showListMarksheetModalAction, admitCard, getObjReqData
} from "../actions/myAccountAction";
import { doPayment,doRetotalingPayment, postUploadDocument , downloadDocument, getUploadDocumentForReCheckEligibility, resetUploadeDocumentChecks, getDepartments} from "../../application_management/actions/advertisementAction";
import myaccountlable from "../labels/my_account_en.json";
import common from "../labels/common_en.json";
import commonApplicationMgmtLabel from "../../application_management/labels/common_en.json"
import { Modal } from 'react-bootstrap';
import profileDetailsLabel from "../../candidate_profile_creation/labels/personal_information_en.json";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import ChnagePassword from "../components/ChangePassword";
import UpdateProfile from "../components/UpdateProfileModal";
import ProfileDetails from "../../application_management/components/ProfileDetails";
import PrintProfileDetails from "../../application_management/components/PrintProfileDetails";
import CancelApplicationModal from "./CancelApplicatonModal";
import ChangeCenter from "./ChangeCenter";
import UpdatePostPreference from "./UpdatePostPreference";
import PaymentHistory from "./PaymentHistory";
import DocumentList from "../../application_management/components/DocumentList";
import ModuleHide from "../../common/components/ModuleHide";
import MarkSheetModal from "./MarkSheetModal";
import PreferenceChangeModal from "./PreferenceChangeModal";
import PrefOptingOutModal from "./PrefOptingOutModal";
import OptingOutModal from "./OptingOutModal";
import RetotalingModel from "./RetotalingModel";
import ViewAppliedDocumentsModal from "./ViewAppliedDocumentsModal";
import AdmitCard from "./AdmitCard"; 
import html2pdf from "html2pdf.js";
import Objection from "./Objection";
import { getRetotalingRequestsList,getRetotalingPostSubjectsList } from "../../application_management/actions/advertisementAction";
import "../../styles/advertisementTable.css";
import "../../styles/advertisement.css";
import InterviewPTLetter from "./InterviewPTLetter";
import { useNavigate } from 'react-router-dom';

const MyAccount = (props) => {
    const navigate = useNavigate();
    const pdfRef = useRef();
    const interviewPTLetterPdfRef = useRef();
    let count = 0;
    const session = JSON.parse(sessionStorage.getItem("session"));
    const emailId = session && session.emailId;
    const mobileNumber = session && session.mobileNumber;
    const profileId = session && session.profileId;
    const [dummy,setDummy] = useState(0);
    const [isNext,setIsNext] = useState(false);
    const [isApply,setIsApply] = useState(false);
    const [applyBtnDisable,setApplyBtnDisable] = useState(false);
    let [showModal, setShowModal] = useState(false);
    let [showPaymentModal, setPaymentModal] = useState(false);
    let [showPaymentData, setPaymentData] = useState(false);
    const [showDocListModal, setshowDocListModal] = useState(false);
    const [showMarksheetListModal, setshowMarksheetListModal] = useState(false);
    const [singleDocList ,setSingleDocList ] = useState("");
    let [showChallan, setShowChallan] = useState(true);
    let [showOnlinePayment, setShowOnlinePayment] = useState(true);
    let [showSbi, setShowSbi] = useState(true);
    let [showBillDesk, setShowBillDesk] = useState(true);   
    let [showEaseBuzz, setShowEaseBuzz] = useState(true);
    let [showCsc, setShowCsc] = useState(true);
    let [showQuickwallet, setShowQuickwallet] = useState(true);
    let [enablepay, setEnablepay] = useState(true);
    let [printprofileModule, setprintprofileModule] = useState({});
    const [arraymModule , setarraymModule] = useState({})
    const [viewApplicationModule , setviewApplicationModule] = useState({})
    const [applicationListModule , setapplicationListModule] = useState({})
    const [updateProfileModule , setupdateProfileModule] = useState({})
    const [applicationData, setApplicationData] = useState({})
    let [currentApplicationData,setCurrentApplicationData] = useState()
    let [isShowRetotalingForm, setShowRetotalingForm] = useState(false);
    let [isRetotalingRequest, setIsRetotalingRequest] = useState(false);
    let [myapplication, setMyApplication] = useState("");
    const [preferenceChanngeModal, setPreferenceChangeModal] = useState(false);
    const [preferenceModal, setPreferenceModal] = useState(false);
    const [optOutModal, setOptOutModal] = useState(false);
    const [applicationNo, setApplicationNo] = useState("");
    const [appliedDocumentModal, setAppliedDocumentModal] = useState(false);
    const [shwModal, setShwModal] = useState(false);
    let [showAdmitCardModal, setShowAdmitCardModal] = useState(false);
    let [isReadOnlyRetotalingStage, setIsReadOnlyRetotalingStage] = useState(false);
    let [isReadOnlyResultMarkSheetStage, setIsReadOnlyResultMarkSheetStage] = useState(false);
    let [isReadOnlyPreferenceChangeStage, setIsReadOnlyPreferenceChangeStage] = useState(false);
    let [isReadOnlyOptingOutStage, setIsReadOnlyOptingOutStage] = useState(false);
    let [isReadOnlyPrefOptOutStage, setIsReadOnlyPrefOptOutStage] = useState(false);
    let [isReadOnlyRecheckEligibilityStage, setIsReadOnlyRecheckEligibilityStage] = useState(false);
    let [isReadOnlyObjectionAnswerKeyStage, setIsReadOnlyObjectionAnswerKeyStage] = useState(false);
    let [showAdditionalQuestionsModal, setAdditionalQuestionsModal] = useState(false);
    let [additionalAnswers, additionalAnswersState] = useState({});
    let [applicableAdditionalQuestionsList, setApplicableAdditionalQuestionsList] = useState([]);
    let [appIdForAdditionalQue, setAppIdForAdditionalQue] = useState("");
    let [allIdsAvailable, setAllIdsAvailable] = useState(false);
    let [showMultipleAdmitCardModal, setShowMultipleAdmitCardModal] = useState(false);
    let [showMultipleInterviewPtLetterModal, setShowMultipleInterviewPtLetterModal] = useState(false);
    let [interviewPTLetterData, setInterviewPTLetterData] = useState('');

    const { register, formState, watch } = useForm({
        mode: "onChange"
    });
    const dispatch = useDispatch();
    const { myApplicationList, examCenterList, profileDetailsInfo, profileViewFlag, applicationDetails, showApplicationDetailsFlag, profileSummaryInfo, isPrintProfile, hasPendingTransactions,
        userDataApplicationStage, reCheckEligibilityResultList, applicationPostPrefListForReCheckEligibility, postPreferenceDetails, getExamTemplateListForImportAdmitCardCsv, examDisplayNameDetails, downloadInterviewPtLetterData} = useSelector(
        (state) => state.myaccount,
        shallowEqual
    );
    let {uploadedDocumentData,retotalingRequests,retotalingPostSubjectsList,allDepartments} = useSelector(state => state.advertisement, shallowEqual);

    useEffect(() => {
        dispatch(getMyApplications());
    }, []);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
   
    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "MY_ACCOUNT_TAB")[0])
        setprintprofileModule(moduleList && moduleList.filter((ele) => ele.moduleName === "VIEW_PROFILE")[0])
        setviewApplicationModule(moduleList && moduleList.filter((ele) => ele.moduleName === "VIEW_APPLICATION")[0])  
        setapplicationListModule(moduleList && moduleList.filter((ele) => ele.moduleName === "APPLICATION_LIST")[0])
        setupdateProfileModule(moduleList && moduleList.filter((ele) => ele.moduleName === "UPDATE_PROFILE")[0])
    },[moduleList])

    const { profileStatus } = useSelector(state => state.profile, shallowEqual);
   
    useEffect(() => {
        if (profileStatus) {
            dispatch(getProfileInfoDisplay());
        }
    }, [profileStatus]);

    useEffect(() => {
        function checkIdsAvailability() {
            if (Object.keys(additionalAnswers).length === 0) {
            setAllIdsAvailable(false); 
            return;
            }
          for (const question of applicableAdditionalQuestionsList) {
            if (
              !additionalAnswers.hasOwnProperty(question.id) ||
              (typeof additionalAnswers[question.id] === "string" && additionalAnswers[question.id].trim() === "") 
            ) {
              setAllIdsAvailable(false); 
              return;
            }
          }
          setAllIdsAvailable(true);
        }
      
        checkIdsAvailability();
      }, [applicableAdditionalQuestionsList, additionalAnswers]);

    const challanDownloadDate = showPaymentData?.advertisement?.challanDownloadEndDate != null ? showPaymentData?.advertisement?.challanDownloadEndDate : showPaymentData?.advertisement?.applnEndDate

    if (!profileDetailsInfo.personalInfoDto && profileDetailsInfo.personalInfo) {
        profileDetailsInfo.personalInfoDto = profileDetailsInfo.personalInfo;
    }
    const showApplicationReceipt = (applicationId) => {
        dispatch(setShowApplicationReceipt(applicationId));
    };

    const showMarkSheetModal =(applicationData, stages)=>{
        setApplicationData(applicationData)
        setshowMarksheetListModal(true)
        dispatch(showListMarksheetModalAction(applicationData.id))
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyResultMarkSheetStage(false);
        } else {
            setIsReadOnlyResultMarkSheetStage(true);
        }
    };

    const showPreferenceChangeModal = (appNo, stages) => {
        setPreferenceChangeModal(true);
        setApplicationNo(appNo);
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyPrefOptOutStage(false);
        } else {
            setIsReadOnlyPrefOptOutStage(true);
        }
    };

    const showOptOutModal = (appNo, stages) => {
        setOptOutModal(true);
        setApplicationNo(appNo);
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyOptingOutStage(false);
        } else {
            setIsReadOnlyOptingOutStage(true);
        }
    };

    const showPreferenceModal = (appNo, stages) => {
        setPreferenceModal(true);
        dispatch(getPostPreferenceDetails(appNo));
        setApplicationNo(appNo);
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyPreferenceChangeStage(false);
        } else {
            setIsReadOnlyPreferenceChangeStage(true);
        }
    };

    const genPdf = () => {
        const htmlContent = document.createElement('div');
        htmlContent.innerHTML = pdfRef.current.innerHTML;
        const options = {
          margin:[0.8,0.4,0.6,0.4],
          filename: 'admit-card.pdf',
          image: { type: 'jpeg', quality: 0.95 },
          html2canvas: { scale: 3, dpi: 300, letterRendering: true },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true},
        };
        html2pdf().from(htmlContent).set(options).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                const textWidth = pdf.getStringUnitWidth(`${i}`) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                const textX = pageWidth - textWidth - 0.5;
                const textY = pageHeight - 0.2;
                const lineY = textY - 0.1;
                const currentDate = new Date();
                const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
                const currentTime = currentDate.toLocaleTimeString([], { hour12: true });
                const dateTime = `${formattedDate} ${currentTime}`;
                pdf.setFontSize(7);
                pdf.text(dateTime, 0.2, textY);
                pdf.text(`${i}`, textX, textY);
                pdf.setLineWidth(0.01);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(0.2, lineY, pageWidth - 0.2, lineY);
              }
          }).save();
      };
       
    const handleClick = (stages) => {
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            if(applicationDetails.isMultipleAdmitcards){
                setShowMultipleAdmitCardModal(true);
                let examId = applicationDetails && applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.exam && applicationDetails.application.advertisement.exam.id;
                dispatch(getExamDisplayNameDetailsForAdmitCard(examId));    
            }else{
                dispatch(admitCard(applicationDetails.application.id))
                .then((response) => {
                    genPdf(pdfRef.current);
                })
                .catch((error) => {
                });
            }
            // setShowMultipleAdmitCardModal(true);
        } else {
            setShowAdmitCardModal(true);
        }
    };

  const viewObjection=(stages)=>{
    setShwModal(true);
    dispatch(getObjReqData(applicationDetails.application.id));
    let currentDate = new Date();
    let targetDate = new Date(stages.endDate);
    currentDate.setUTCHours(0, 0, 0, 0);
    if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
        setIsReadOnlyObjectionAnswerKeyStage(false);
    } else {
        setIsReadOnlyObjectionAnswerKeyStage(true);
    }
  };

    const gotoApplicationStage = (stageDetail, applicationId, advertisementId )=>{
        userDataApplicationStage.applicationId = applicationId;
        userDataApplicationStage.advertisementId = advertisementId;
        dispatch(externalApplicationStage(stageDetail ,userDataApplicationStage, applicationId, advertisementId))
    }

    const reCheckEligibility = (application, stages)=>{
        dispatch(reCheckEligibilityAction(application.id))
        setIsApply(true)
        setIsNext(false)
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyRecheckEligibilityStage(false);
        } else {
            setIsReadOnlyRecheckEligibilityStage(true);
        }
    }

    const showApplicationDetails = (myapplication) => {
        setCurrentApplicationData(myapplication);
        dispatch(setShowApplicationDetails(myapplication.id));
        dispatch(getProfileInfoDisplay(myapplication.profileId));
        dispatch(setChangeCenterModal(false, myapplication.advertisement.id,myapplication.id));
        setIsApply(false);
        window.scrollTo(0,0);
    }

    const showApplyRetotalingSec=(applicationData, stage)=>{
        dispatch(getRetotalingRequestsList(applicationData.id)); 
        dispatch(getRetotalingPostSubjectsList(applicationData.id));
        setIsRetotalingRequest(true);
        setShowRetotalingForm(true);
        let currentDate = new Date();
        let targetDate = new Date(stage.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setIsReadOnlyRetotalingStage(false);
        } else {
            setIsReadOnlyRetotalingStage(true);
        }
    };

    const showProfileView = () => {
        dispatch(getProfileInfoDisplay());
        dispatch(setProfileViewFlg(true))
        dispatch(setApplicationDetailsFlg(false))
    }
    
    const ChangePasswordModal = () => {
        dispatch(setChangePasswordFlg(true))
    }
   
    const updateProfileModal = () => {  
        if(profileSummaryInfo && profileSummaryInfo.isProdEnv){
            dispatch(setUpdateProfileflg(true, updateProfileModule && updateProfileModule.isEnabled == false, props, navigate))
        }else{
            dispatch(updateProfileRequest(props, navigate))
        }
        // dispatch(setUpdateProfileflg(true, updateProfileModule && updateProfileModule.isEnabled == false, props))

    }  

    function getMobileMask(mobileno) {
        if (mobileno) {
            const mobilenumber = mobileno.toString();
            const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(2, 8), 'XXXXXX')
            return maskMobileNumber
        }
    }

    const cancelMyApplication = (myapplication) => {
        setShowModal(true);
        setMyApplication(myapplication);
    }

    const viewPaymentHistory = (myapplication)=>{
        dispatch(setPaymentHistoryModal(true, myapplication.id))
    }
    
    const gotoPayment = (myapplication) =>{
        setPaymentModal(true)
        setPaymentData(myapplication)
        const enabledPaymentMethods  = JSON.parse(myapplication && myapplication.advertisementConfig && myapplication.advertisementConfig.enabledPaymentMethods)
        const disabledPaymentMethods = JSON.parse(myapplication && myapplication.advertisementConfig && myapplication.advertisementConfig.disabledPaymentMethods)
        if(enabledPaymentMethods !== null && disabledPaymentMethods !== null){
            toastr.error(common.PAYMENT_METHODS_NULL_ERROR)
            setEnablepay(false);
        }
        if (enabledPaymentMethods !== null  ) {
            setShowChallan( enabledPaymentMethods.includes("challan"));
            setShowOnlinePayment(enabledPaymentMethods.includes("sbiepay") ||
            enabledPaymentMethods.includes("billdesk") || 
            enabledPaymentMethods.includes("csc") ||
            enabledPaymentMethods.includes("quikwallet")||
            enabledPaymentMethods.includes("easebuzz"));
            setShowSbi(enabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(enabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(enabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(enabledPaymentMethods.includes("quikwallet"));
            setShowCsc(enabledPaymentMethods.includes("csc"));
        }
        if (disabledPaymentMethods !== null) {
            setShowChallan(!disabledPaymentMethods.includes("challan"));
            setShowOnlinePayment(!disabledPaymentMethods.includes("sbiepay") ||
            !disabledPaymentMethods.includes("billdesk") ||
            !disabledPaymentMethods.includes("csc") ||
            !disabledPaymentMethods.includes("quikwallet") ||
            !disabledPaymentMethods.includes("easebuzz"));
            setShowSbi(!disabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(!disabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(!disabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(!disabledPaymentMethods.includes("quikwallet"));
            setShowCsc(!disabledPaymentMethods.includes("csc"));
        }         
    }
    const gotoRetotalingPayment = (requestData,myapplication) =>{
        setPaymentModal(true);
        setShowChallan(false);
        setPaymentData(requestData)
        const enabledPaymentMethods  = JSON.parse(myapplication && myapplication.advertisementConfig && myapplication.advertisementConfig.enabledPaymentMethods)
        const disabledPaymentMethods = JSON.parse(myapplication && myapplication.advertisementConfig && myapplication.advertisementConfig.disabledPaymentMethods)
        if(enabledPaymentMethods !== null && disabledPaymentMethods !== null){
            toastr.error(common.PAYMENT_METHODS_NULL_ERROR)
            setEnablepay(false);
        }
        if (enabledPaymentMethods !== null  ) {
            setShowOnlinePayment(enabledPaymentMethods.includes("sbiepay") ||
            enabledPaymentMethods.includes("billdesk") || 
            enabledPaymentMethods.includes("csc") ||
            enabledPaymentMethods.includes("quikwallet")  ||
            enabledPaymentMethods.includes("easebuzz"));
            setShowSbi(enabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(enabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(enabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(enabledPaymentMethods.includes("quikwallet"));
            setShowCsc(enabledPaymentMethods.includes("csc"));
        }
        if (disabledPaymentMethods !== null) {
            setShowOnlinePayment(!disabledPaymentMethods.includes("sbiepay") ||
            !disabledPaymentMethods.includes("billdesk") || 
            !disabledPaymentMethods.includes("csc") ||
            !disabledPaymentMethods.includes("quikwallet") ||
            !disabledPaymentMethods.includes("easebuzz"));
            setShowSbi(!disabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(!disabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(!disabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(!disabledPaymentMethods.includes("quikwallet"));
            setShowCsc(!disabledPaymentMethods.includes("csc"));
        }         
    };

    const processToPayment =(paymentData,paymenttype) =>{
        let data={}
        data.paymentLocationTab = "my_account"
        data.paymentBank= watch("paymentBank")
        if(paymentData.type=='retotaling')
        {
            dispatch(doRetotalingPayment(paymentData.id,paymenttype, data))
            setPaymentModal(false)
        setShowChallan(false)
        setShowOnlinePayment(true);
        setShowSbi(true);
        setShowBillDesk(true);
        setShowEaseBuzz(true);
        setShowQuickwallet(true)
        setShowCsc(true) 
        }
        else
        {
            if(paymenttype){
                dispatch(doPayment(paymentData.id,paymenttype, data))
            } 
            setPaymentModal(false)
        setShowChallan(true)
        setShowOnlinePayment(true);
        setShowSbi(true);
        setShowBillDesk(true);
        setShowEaseBuzz(true);
        setShowQuickwallet(true);
        setShowCsc(true); 
        }
    }
    
    const printProfile =async()=>{
       dispatch(setPrintProfile(true))
    }
    const printApplication = async()=>{
         window.print();
    }

    const onChangeDocumentInMyAccount = (event,doc,applicationId) => {
        if(event.target.files.length == 0) {
            return
        }
        let requestBody={}
        requestBody.uploadPdfDoc =   event.target.files[0]
        requestBody.documentTypeId = doc.documentTypeId
        requestBody.applicationId = applicationId
        dispatch(postUploadDocument(requestBody))  
    }

    const viewDocument = (doc) => {
        dispatch(downloadDocument(doc.documentInfoId,doc.documentTypeName))
    }
   
    const handleChangeCenter = (applicationDetails) => {
       dispatch(setChangeCenterModal(true,applicationDetails.advertisement.id,applicationDetails.id));
    }

    const handleUpdatePrefModal = () => {
        dispatch(setUpdatePostModal(true));
    }

    const getPostName = (postCriteriaId, isDvng) => {
        const examPost = reCheckEligibilityResultList?.examPostList?.find(examPost => examPost.postCriteria.id == postCriteriaId);
        if (!examPost) {
            return `Unknown (${postCriteriaId})`
        }
        return isDvng ? examPost.postCriteria.nameDvng : examPost.postCriteria.name;
    }

    const movePostPrefUp = (index) => {
        if (index == 0) return;
        const tmp = applicationPostPrefListForReCheckEligibility[index];
        applicationPostPrefListForReCheckEligibility[index] = applicationPostPrefListForReCheckEligibility[index-1];
        applicationPostPrefListForReCheckEligibility[index-1] = tmp;
        setDummy(dummy+1);
    }

    const movePostPrefDown = (index) => {
        if (index >= (applicationPostPrefListForReCheckEligibility.length)) return;
        const tmp = applicationPostPrefListForReCheckEligibility[index];
        applicationPostPrefListForReCheckEligibility[index] = applicationPostPrefListForReCheckEligibility[index+1];
        applicationPostPrefListForReCheckEligibility[index+1] = tmp;
        setDummy(dummy+1);
    }

    const handlePrefChange = (postPref, name, value) => {
        postPref[name] = value;
        const foundTrueValue = applicationPostPrefListForReCheckEligibility && applicationPostPrefListForReCheckEligibility
        .filter((elgbResult) => elgbResult.isEligible) 
        .find(element => element.isInterested == true);
        if(foundTrueValue){
            setApplyBtnDisable(true)
        }else{
            setApplyBtnDisable(false)
        }
        setDummy(dummy+1);
    }

    const resetPref = () => {
        setApplyBtnDisable(false)
        dispatch(resetApplicationPostPrefListForReCheckEligibility());
    }

    const doNext = () => {
        setIsNext(true);
          let post = applicationPostPrefListForReCheckEligibility
              .filter(pref => pref.id==null && pref.isInterested)
              .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
              dispatch(getUploadDocumentForReCheckEligibility(reCheckEligibilityResultList.advertisementId,post,applicationDetails.application.id))
      }

      const handleDocChange= (doc, isUploaded, value) => {
        doc[isUploaded] = value;
        setDummy(dummy+1);
   }

    const handleDocumentListShow = (docList) => {
        setshowDocListModal(true)
        setSingleDocList(docList) 
    } 

    const onChangeDocument = (event,doc) => {
        let post = applicationPostPrefListForReCheckEligibility
            .filter(pref => pref.isInterested)
            .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
            if(event.target.files.length == 0) {
            return
            }
            let requestBody={}
            requestBody.uploadPdfDoc =   event.target.files[0]
            requestBody.documentTypeId = doc.documentTypeId
            requestBody.applicationId = applicationDetails.application.id;
            requestBody.profileId = applicationDetails.application.profileId;
            dispatch(postUploadDocument(requestBody,reCheckEligibilityResultList.advertisementId,post))  
    }

    const goBack = () => {
        setIsNext(false);
       dispatch(resetUploadeDocumentChecks())
    }

    const onApply = () => {
        const foundTrueValue = uploadedDocumentData && uploadedDocumentData.find(element => !element. isUploaded );
        if(foundTrueValue){
            toastr.error(advertisementDetailsLabel.UPLOAD_DOC_INFO_NOTE);
        }else{
        let advertisementId = reCheckEligibilityResultList.advertisementId;
            const applnData = {
                advertisementId,
                applicationId : applicationDetails.application.id,
                applicationPostPrefList:
                applicationPostPrefListForReCheckEligibility
                        .filter(pref => pref.isInterested)
                        .map((pref, index) => ({ ...pref, prefOrder: index + 1,applicationId:applicationDetails.application.id })),
                requiredDocumentsSelected:
                uploadedDocumentData
                        .map((doc, index) => ({ ...doc, documentInfoId:doc.isUploaded ? doc.documentInfoId:null })),
            }
            dispatch(updateFeesPaidApplication(applnData))
            setIsNext(false);
            setIsApply(false);
        }
    };

    const handleQuestions = (evt) => {
        const value = evt.target.value;
        additionalAnswersState({
            ...additionalAnswers,
            [evt.target.name]: value,
        });
    };

    const handleAddtionalQuestionsChange = (myapplicationData) => {
        setApplicableAdditionalQuestionsList(myapplicationData && myapplicationData.additionalQuestionsList)
        setAdditionalQuestionsModal(true);
        setAppIdForAdditionalQue(myapplicationData && myapplicationData.application && myapplicationData.application.id)
    };

    const handleAddtionalQuestionsSubmit = () => {
        setAdditionalQuestionsModal(false);
        let params = {
            "additionalAnswers":  JSON.stringify(additionalAnswers),
            "additionalQuestions": JSON.stringify(applicableAdditionalQuestionsList)
        }
        dispatch(setAdditonalQuestions(appIdForAdditionalQue,params));
        additionalAnswersState("");
    };

    const handleCancelAdditionalQuestionsMoal = () => {
        setAdditionalQuestionsModal(false);
        additionalAnswersState("");
    };

    let additionalQuestionsArr = applicationDetails && applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.additionalQuestions && JSON.parse(applicationDetails.application.advertisement.additionalQuestions);                                 
   
    const additionalAnswersDetails = (id) => {
        let additionalAnswersParseData = applicationDetails && applicationDetails.application && applicationDetails.application.additionalAnswers && JSON.parse(applicationDetails.application.additionalAnswers);
        if (additionalAnswersParseData) {
            if (additionalAnswersParseData.hasOwnProperty(id)) {
                if(additionalAnswersParseData[id] === true || additionalAnswersParseData[id] === "true"){
                    return "Yes"
                }else if(additionalAnswersParseData[id] === false || additionalAnswersParseData[id] === "false"){
                    return "No"
                }else{
                return additionalAnswersParseData[id];
                }
            }
        }
    };

    const handleDownloadAdmitCardClick = (ele) => {
        let applId = applicationDetails.application.id;
        let tempId = ele.id;
        dispatch(admitCard(applId, tempId))
        .then((response) => {
            genPdf(pdfRef.current);
        })
        .catch((error) => {
        });
    };

    const interviewPTLetterGenPdf = () => {
        const htmlContent = document.createElement('div');
        htmlContent.innerHTML = interviewPTLetterPdfRef.current.innerHTML;
        const options = {
          margin:[0.8,0.4,0.6,0.4],
          filename: 'InterviewLetter.pdf',
          image: { type: 'jpeg', quality: 0.95 },
        //   html2canvas: { scale: 3, dpi: 300, letterRendering: true },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true},
        };
        html2pdf().from(htmlContent).set(options).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                const textWidth = pdf.getStringUnitWidth(`${i}`) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                const textX = pageWidth - textWidth + 0.5;
                const textY = pageHeight - 0.2;
                const lineY = textY - 0.1;
                const currentDate = new Date();
                const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
                const currentTime = currentDate.toLocaleTimeString([], { hour12: true });
                const dateTime = `${formattedDate} ${currentTime}`;
                pdf.setFontSize(8);
                pdf.text(dateTime, 0.2, textY);
                pdf.text(`${i}`, textX, textY);
                pdf.setLineWidth(0.01);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(0.2, lineY, pageWidth - 0.2, lineY);
              }
          }).save();
      };

    const handleInterviewPTLetterStageBtnClick = (stages) => {
        let currentDate = new Date();
        let targetDate = new Date(stages.endDate);
        currentDate.setUTCHours(0, 0, 0, 0);
        if ((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)) {
            setShowMultipleInterviewPtLetterModal(true);
            dispatch(downloadInterviewPTLetterAPI(applicationDetails.application.id));
        } else {
            // setShowAdmitCardModal(true);
        }
    };

    const handleDownloadInterviewPTLetterBtnClick = (ele, event) => {
        event.preventDefault();
        if(ele){
            setInterviewPTLetterData(ele);
            dispatch(downloadInterviewPTLetterAPI(applicationDetails.application.id))
            .then((response) => {
                interviewPTLetterGenPdf(interviewPTLetterPdfRef.current);
            })
            .catch((error) => {
            });
        }
    };

    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    isPrintProfile?(
        <div>
        <PrintProfileDetails applicationData={currentApplicationData} applicationPostData = {applicationDetails&& applicationDetails.applicationPostPrefDtoList && applicationDetails.applicationPostPrefDtoList.filter((e=> e.preferredAppointingAuthorities))}/>
        </div>
        ):
        (
        <div>
        
        <div className="row">
            <main role="main" className="col-sm-7 col-md-8 col-lg-9 border-right" id="main_block">
                {!profileViewFlag?
                <div className="box-one" id="hide_Block">
                    <h6>{common.PROFILE_SUMMARY}</h6>
                    {profileSummaryInfo && profileSummaryInfo.englishFullName && profileSummaryInfo.devnagariFullName ?
                        <div className="box-contents">
                            <div className="row">
                                <div className="col-md-2">
                                    {profileSummaryInfo && profileSummaryInfo.photo ?
                                        <img
                                            className="img-profile"
                                            src={`data:image/png;base64,${profileSummaryInfo && profileSummaryInfo.photo}`}
                                            width="130" height="auto" />
                                        : ""}
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {profileSummaryInfo && profileSummaryInfo.englishFullName ?
                                                <div className="content-row">
                                                    <h6> {profileDetailsLabel.FULLNAME_ENGLISH}</h6>
                                                    <span className="word-break">{
                                                        profileSummaryInfo && profileSummaryInfo
                                                            .englishFullName
                                                    }
                                                    </span>
                                                </div>
                                                : ""}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clearfix">
                                                <h6>{common.PROFILE_STATUS}</h6>
                                                {profileStatus ?
                                                    <span className="text-success">{common.COMPLETE}</span> :
                                                    <span className="text-warning">{common.IN_PROGRESS}</span>
                                                }
                                                {profileStatus ?
                                                    <div className="mt21">
                                                            <a className="pull-right btn btn-outline-primary btn-sm" onClick={showProfileView}>{common.VIEW_PROFILE}</a>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            {profileSummaryInfo && profileSummaryInfo.devnagariFullName ?
                                                <div className="content-row">
                                                    <h6>{profileDetailsLabel.FULLNAME_DEVNAGARI}</h6>
                                                    <span>{profileSummaryInfo && profileSummaryInfo.devnagariFullName}</span>
                                                </div>
                                                : ""}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clearfix">
                                                <h6>{common.UPDATE_ON}</h6>
                                                <span>{profileSummaryInfo.profile && profileSummaryInfo.profile.updatedAt ? dayjs(profileSummaryInfo.profile.updatedAt).format(common.DATE_FORMAT) : ""}</span>
                                                {profileStatus ?
                                                    <a className="pull-right btn btn-outline-primary btn-sm" onClick={updateProfileModal}>{common.UPDATE_PROFILE}</a>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.MOBILE_NO}</h6>
                                                <span>
                                                    {getMobileMask(
                                                        mobileNumber
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clearfix">
                                                <h6>{profileDetailsLabel.Email_ID} </h6>
                                                <span> {emailId ? emailId.replace(emailId.substring(2, 12), 'XXXXXX') : ""}</span>
                                                <a className="pull-right btn btn-outline-primary btn-sm" onClick={ChangePasswordModal}>{common.CHANGEPwd}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : <div className="box-contents">
                            <div className="row">
                                <div className="col-md-6"></div>

                                <div className="col-md-6">
                                    <div className="content-row clearfix">
                                        <h6>{common.PROFILE_STATUS}</h6>
                                        {profileStatus && profileId ?
                                            <span className="text-success">{common.COMPLETE}</span> : !profileStatus && profileId ?
                                            <span className="text-warning">{common.IN_PROGRESS}</span>: profileId ==null?
                                            <span className="text-warning">{common.INCOMPLETE}</span>:""
                                        }
                                    </div>
                                </div>

                            </div>
                            <hr className="mar_top_bottom_8" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row">
                                        <h6>{profileDetailsLabel.MOBILE_NO}</h6>
                                        <span>
                                            {getMobileMask(
                                                mobileNumber
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="content-row clearfix">
                                        <h6>{profileDetailsLabel.Email_ID} </h6>
                                        <span> {emailId ? emailId.replace(emailId.substring(2, 12), 'XXXXXX') : ""}</span>
                                        <a className="pull-right btn btn-outline-primary btn-sm" onClick={ChangePasswordModal}>{common.CHANGEPwd}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>:""}
                    
                {myApplicationList.length > 0 && !profileViewFlag?
                    <div>
                        {applicationListModule && applicationListModule.isEnabled == false ? 
                        <div className="bg-white p-4 mt-4"> <p className="Note-color f13">{applicationListModule.msgToDisplay}</p></div>
                        :
                        <div className="box-one" id="hide_Block">
                            <h6>{common.MY_APPLICATION}
                                <div className="clearfix"></div>
                            </h6>
                            <div className="box-contents overflow-auto">
                                <table className="table table-bordered table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>{myaccountlable.APPLIED_ON}</th>
                                            <th>{myaccountlable.APPLICATION_ID}</th>
                                            <th>{myaccountlable.ADVERTISEMENT_NUMBER}</th>
                                            <th>{myaccountlable.ADVERTISEMENT_NAME}</th>
                                            <th >{myaccountlable.STATUS}</th>
                                            <th ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myApplicationList && myApplicationList.map((myapplication) => (
                                            <tr key={myapplication && myapplication.application && myapplication.application.id}>
                                                <td  className="word-break w16">
                                                    {myapplication && myapplication.application && myapplication.application.createdAt
                                                        ? dayjs(
                                                            myapplication && myapplication.application && myapplication.application.createdAt
                                                        ).format(common.CURRENT_DATE_TIME_FORMAT)
                                                        : ""}
                                                </td>
                                                <td  className="word-break">{myapplication && myapplication.application && myapplication.application.applicationNo}</td>
                                                <td className="word-break">
                                                    {
                                                        myapplication && myapplication.application && myapplication.application.advertisement
                                                            .advertisementNo
                                                    }
                                                </td>
                                                <td className="word-break">{myapplication && myapplication.application && myapplication.application.advertisement.name}</td>
                                                <td className="word-break">
                                                    {myapplication && myapplication.application && myapplication.application.paymentRefNo != null ?
                                                        <>{myapplication && myapplication.applicatio && myapplication.application.isRejected ? <span className="text-danger"><i className='fa fa-info-circle text-danger mr-1 f16' data-toggle="tooltip" title={myapplication && myapplication.application && myapplication.application.reasonForRejection} aria-hidden="true" ></i>{common.APPLICATION_REJECTED}</span> : <span className="text-success">{myaccountlable.FEES_PAID}</span>}</>
                                                        : <span className="text-danger">{common.FEES_NOT_PAID}</span>}
                                                </td>
                                                <td  className="word-break vertical w15">
                                                    <div>
                                                        <button type="button" className="btn btn-outline-primary btn-sm mr-1" onClick={() => showApplicationDetails(myapplication && myapplication.application)}>
                                                            {common.VIEW}
                                                        </button>
                                                        <button  type="button" disabled={myapplication && myapplication.application && ((myapplication.application.paymentRefNo !="" && myapplication.application.paymentRefNo !=null) || !myapplication.application.flgAllDocumentsUploaded
                                                            || (dayjs(myapplication && myapplication.application && myapplication.application.advertisement.challanPaymentEndDate).isBefore(new Date(),'day') && dayjs(myapplication && myapplication.application && myapplication.application.advertisement.onlinePaymentEndDate).isBefore(new Date(),'day')) )}  className="btn btn-outline-primary btn-sm mr-1" 
                                                            onClick={() => { gotoPayment(myapplication && myapplication.application) }}>
                                                            {myaccountlable.PAY_NOW}
                                                        </button>
                                                        <br/>
                                                        <button type="button" className="btn btn-outline-dark btn-sm mt-1" disabled={myapplication && myapplication.application && myapplication.application.advertisement && dayjs(myapplication.application.advertisement.applnEndDate).isBefore(new Date(),'day')}
                                                            onClick={() => { cancelMyApplication(myapplication && myapplication.application) }}>
                                                            {common.CANCEL_APPLICATION}
                                                        </button>
                                                        <button type="button" className="btn btn-outline-primary btn-sm mt-1" onClick={()=> {viewPaymentHistory(myapplication && myapplication.application)}}>
                                                            {myaccountlable.PAYMENT_HISTORY}
                                                        </button>
                                                        {(myapplication && myapplication.isActionRequired) &&
                                                        <button type="button" className="btn btn-outline-primary btn-sm mt-1 text-danger" onClick={()=> handleAddtionalQuestionsChange(myapplication)}>
                                                            {myaccountlable.QUESTIONS}
                                                        </button>}
                                                    </div>
                                                    {   hasPendingTransactions?.applicationIds?.indexOf(myapplication && myapplication.application && myapplication.application.id) >= 0 &&
                                                        <button type="button" className="btn btn-outline-primary btn-sm mr-1 mt-1"
                                                            onClick={() => { dispatch(reconcileNow([myapplication && myapplication.application && myapplication.application.id])) }}>
                                                            {common.CHECK_PAYMENT_STATUS}
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> }
                        {showApplicationDetailsFlag ?
                            <>
                            {viewApplicationModule && viewApplicationModule.isEnabled == false ? 
                            <div className="bg-white p-4 mt-4"> <p className="Note-color f13">{viewApplicationModule.msgToDisplay}</p></div>    
                            
                            : 
                                <div className="box-one print_block">
                                    <h6>{myaccountlable.APPLICATION_DETAILS}
                                    <button id="hide_Block" type="button"  className="btn btn-primary btn-sm float-right" onClick={() =>printApplication()} >{myaccountlable.PRINT_SAVE}</button>
                                    <div className="clearfix"></div>
                                </h6>
                                <div className="box-contents">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="content-row">
                                                <h6> {myaccountlable.ADVERTISEMENT_NUMBER}</h6>
                                                {
                                                    applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement
                                                        .advertisementNo
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="content-row">
                                                <h6> {myaccountlable.APPLICATION_ID}</h6>
                                                {
                                                    applicationDetails.application && applicationDetails.application.applicationNo 
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="content-row">
                                                <h6> {myaccountlable.APPLIED_ON}</h6>
                                                {applicationDetails && applicationDetails.application && applicationDetails.application.createdAt
                                                    ? dayjs(
                                                        applicationDetails.application.createdAt
                                                    ).format(common.CURRENT_DATE_TIME_FORMAT)
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row">
                                                <h6>{myaccountlable.ADVERTISEMENT_NAME_ENG}</h6>
                                                {
                                                    applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.name
                                                }
                                            </div>
                                            <div className="content-row">
                                                <h6>{myaccountlable.APPLICATION_START_DATE}</h6>
                                                {applicationDetails.application && applicationDetails.application.advertisement
                                                    ? dayjs(
                                                        applicationDetails.application.advertisement.applnStartDate
                                                    ).format(common.DATE_FORMAT)
                                                    : ""
                                                }
                                            </div>
                                            <div className="content-row">
                                                <h6>{myaccountlable.EXAM_NAME}</h6>
                                                {
                                                    applicationDetails.application && applicationDetails.application
                                                        .advertisement && applicationDetails.application.advertisement.exam.name
                                                }
                                            </div>
                                            {applicationDetails.application.flgDisabilityScribeReq !=null?
                                            <div className="content-row">
                                                <h6>{advertisementDetailsLabel.DISABILITY_SCRIBE_REQ}</h6>
                                                {
                                                    applicationDetails.application.flgDisabilityScribeReq?
                                                    "Yes":"No"
                                                }
                                            </div>:""
                                            }
                                            {applicationDetails.application.flgDisabilityCompTime !=null?
                                            <div className="content-row">
                                                <h6>{advertisementDetailsLabel.DISABILITY_COMP_TIME}</h6>
                                                {
                                                    applicationDetails.application.flgDisabilityCompTime?
                                                    "Yes":"No"
                                                }
                                            </div>:""
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row">
                                                <h6> {myaccountlable.ADVERTISEMENT_NAME_MARATHI}</h6>
                                                {
                                                    applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.nameDvng
                                                }
                                            </div>
                                            <div className="content-row">
                                                <h6>{myaccountlable.APPLICATION_LAST_DATE}</h6>
                                                {applicationDetails.application && applicationDetails.application.advertisement
                                                    ? dayjs(
                                                        applicationDetails.application.advertisement.applnEndDate
                                                    ).format(common.DATE_FORMAT)
                                                    : ""
                                                }
                                            </div>
                                            {applicationDetails.application.scribeArrangedBy != ""?
                                            <div className="content-row">
                                                <h6>{advertisementDetailsLabel.SCRIBE_ARRANGED_BY}</h6>
                                                {
                                                    applicationDetails.application.scribeArrangedBy == 1?
                                                    "Self":"MPSC Office"
                                                }
                                            </div>:""
                                            }

                                        </div>
                                    </div>
                                    <hr className="mar_top_bottom_8" />
                                    <h6>{myaccountlable.EXAM_CENTER_NAME}
                                        {applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.examCenterChangeEndDate !=null && !dayjs(applicationDetails.application.advertisement.examCenterChangeEndDate).isBefore(new Date(),'day')?
                                            <button className="pull-right btn btn-outline-primary btn-sm mt5" disabled={examCenterList.length < 1}  onClick={(e)=> handleChangeCenter(applicationDetails.application)}>{common.CHANG_CENTER}</button>  
                                            :null
                                        } 
                                        </h6>
                                        <hr className="mar_top_bottom_8" />
                                    {applicationDetails && applicationDetails.applicationExamCenterPrefDto && applicationDetails.applicationExamCenterPrefDto ?
                                        applicationDetails.applicationExamCenterPrefDto.map((ele, index) => {
                                            if (ele) {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-1">{index + 1}</div>
                                                            <div className="col-md-11">
                                                                <div className="content-row">
                                                                    {ele.examCenterName} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="mar_top_bottom_8" />
                                                    </div>
                                                )
                                            }
                                        })
                                        : ""
                                    }
                                    <h6>{myaccountlable.ELIGIBLE_POST}
                                    {applicationDetails.application && applicationDetails.application.advertisement && applicationDetails.application.advertisement.postPrefChangeEndDate!=null && !dayjs(applicationDetails.application.advertisement.postPrefChangeEndDate).isBefore(new Date(),'day')? 
                                        <button className="pull-right btn btn-outline-primary btn-sm mt5" disabled={applicationDetails.applicationPostPrefDtoList.length == 1} onClick={handleUpdatePrefModal}>{common.UPDATE_PREF}</button>                                      
                                        :null
                                    }
                                        </h6>
                                    <hr className="mar_top_bottom_8" />
                                    {applicationDetails && applicationDetails.applicationPostPrefDtoList ?
                                        applicationDetails.applicationPostPrefDtoList.sort((a, b) => a.prefOrder < b.prefOrder ? - 1 : 1).map((ele, index) => {
                                            if (ele) {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-1">{index + 1}</div>
                                                            <div className="col-md-11">
                                                                <div className="content-row">
                                                                    {ele.postName} | {ele.postNameDvng}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="mar_top_bottom_8" />
                                                    </div>
                                                )
                                            }
                                        })
                                        : ""
                                    }
                                    {applicationDetails.requiredDocuments && applicationDetails.requiredDocuments.length>0?
                                    <div className="card">
                                    <div className="card-header">
                                            {advertisementDetailsLabel.Required_Document_for_Application}
                                        </div>
                                        <div className="card-body">
                                                {applicationDetails.requiredDocuments && applicationDetails.requiredDocuments.map((doc,i) => ( 
                                                    <>
                                                    <div key={i} className="content-row clearfix pad_0 pr-2 d-inline-block w425">
                                                        <label>{doc.documentTypeName}</label>
                                                        <div className="float-right">
                                                            <input type="file" name="documentId" id={"documentId"+i} disabled={doc.status =="Rejected_No_Reupload" || doc.status =="Approved" || doc.status=="Uploaded"} onChange={(event) => onChangeDocumentInMyAccount(event,doc,applicationDetails.application.id)} className="d-none" accept="application/pdf"/>
                                                             {doc.status== 'Rejected'?
                                                            <label for={"documentId"+i}  className="text-primary">
                                                                <span className="pull-right btn btn-outline-primary btn-sm cursor-pointer" >{advertisementDetailsLabel.RE_UPLOAD}</span>
                                                            </label>
                                                            :doc.status == null?
                                                            <label for={"documentId"+i}  className="text-primary">
                                                                <span className="pull-right btn btn-outline-primary btn-sm cursor-pointer">{advertisementDetailsLabel.UPLOAD}</span>
                                                            </label>
                                                            :""
                                                            } &nbsp;&nbsp;
                                                            <label>
                                                                {doc.status=='Rejected'?
                                                                    <span className="pull-right text-danger">{common.REJECTED}</span>
                                                                    : doc.status=='Approved'?
                                                                    <span className="pull-right text-success">{common.APPROVED}</span>
                                                                    : doc.status=='Uploaded'?
                                                                    <span className="pull-right text-secondary">{common.UPLOADED}</span>
                                                                    : doc.status  == "Rejected_No_Reupload"?     
                                                                    <span className="pull-right text-danger">{common.REJECTED_NO_RE_UPLOAD}</span>
                                                                    : <span className="pull-right text-danger">{advertisementDetailsLabel.NOT_UPLOADED}</span>
                                                                }
                                                             </label>
                                                            &nbsp;&nbsp;
                                                            {doc.documentInfoId ?
                                                            <label onClick={(e)=>{viewDocument(doc)}}>    
                                                                <span className="pull-right text-primary cursor-pointer">{common.VIEW}</span>
                                                                </label>
                                                            :""
                                                            }
                                                        </div><br/>
                                                        {(doc.remarks !=null && doc.remarks !== "")?
                                                        <div className="text-danger d-inline-block">
                                                            <label className="fw-bold mb-0">{common.COMMENT}</label> 
                                                            &nbsp;&nbsp;
                                                            {doc.remarks}
                                                        </div>
                                                        :null}
                                                    </div>&nbsp;&nbsp;&nbsp;
                                                  </>
                                            ))}    
                                        </div>
                                    </div>:""}
                                    <hr className="mar_top_bottom_8" />
                                    {additionalQuestionsArr && additionalQuestionsArr.length > 0 ? 
                                    <div className="card ">
                                        <div className="card-header">
                                            <span> {advertisementDetailsLabel.QUESTION_DESCRIPTION} </span>
                                            <span className=" float-right"> {advertisementDetailsLabel.ANSWER} </span>
                                        </div>
                                        <div className="card-body pad_top_bottom_8">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="content-row clearfix">
                                                        {additionalQuestionsArr && additionalQuestionsArr.map((ele,i) => ( 
                                                            <>
                                                                <div key={i} className="content-row clearfix pt-0 mt-0">
                                                                    <label>{ele.question}</label>
                                                                    <div className="float-right">
                                                                        <label>
                                                                            {additionalAnswersDetails(ele.id)}
                                                                        </label>
                                                                    </div><br/>
                                                                </div>&nbsp;&nbsp;&nbsp;
                                                            </>
                                                        ))}  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :""}
                                </div>
                                    </div>
                            }
                           </>         
                            : ""
                        } 
                    </div>
                    : ""
                }
                {profileViewFlag ?
                    <div className="box-one">
                        <ProfileDetails />
                    </div>
                    : ""
                }
            </main>
            <div className=" col-sm-5 col-md-4 col-lg-3 d-md-block">
            <div >
                {!profileViewFlag && showApplicationDetailsFlag ?
                <div className="box-two" id="hide_Block">
                    <h6>{common.RECENT_NOTIFICATIONS}</h6>
                    <div className="box-contents">
                            <ul className="notype">
                                    <li>
                                        <div className="row">
                                            <div className="col-8">
                                                <b>{common.FEES_RECEIPT}</b>
                                            </div>
                                            <div className="col-4 text-right">
                                                <button type="button" className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.paymentRefNo == null || applicationDetails.application.paymentRefNo.startsWith("NA_") || applicationDetails.application.isOptingOut} onClick={() => showApplicationReceipt(applicationDetails.application && applicationDetails.application.id)}>
                                                    {common.VIEW}
                                             </button>
                                            </div>
                                        </div>
                                    </li>
                                        {applicationDetails && applicationDetails.advertisementStages.map((stages) => (
                                           stages && stages.stage && stages.stage.code == "ADMIT_CARDS" && (stages.stage.type).toLowerCase() == "internal" && stages.stage.code != "RECHECK_ELIGIBILITY"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} 
                                                                onClick={() => { handleClick(stages) }}>
                                                                    {common.VIEW}
                                                               </button> 
                                                               <div className="d-none" ref={pdfRef}><AdmitCard  /></div></div>
                                                                 <div>
                                                                  </div>
                                                        </div>
                                                     </li>                                    
                                                : null
                                        ))}

                                        {/* {interview letter/PT letter}*/}

                                        {applicationDetails && applicationDetails.advertisementStages.map((stages) => (
                                           stages && stages.stage && stages.stage.code == "INTERVIEW_LETTER_PT_LETTER" && (stages.stage.type).toLowerCase() == "internal" && stages.stage.code != "RECHECK_ELIGIBILITY"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} 
                                                                onClick={() => { handleInterviewPTLetterStageBtnClick(stages) }}>
                                                                    {common.VIEW}
                                                               </button> 
                                                                <div className="d-none" ref={interviewPTLetterPdfRef}>
                                                                    <InterviewPTLetter  interviewPTLetterData={interviewPTLetterData}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     </li>                                    
                                                : null
                                        ))}

                                        {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => appStage.stage.type.toLowerCase() === "external_redirect").map((stages) => {
                                            if (stages && stages.stage && stages.stage.type.toLowerCase() === "external_redirect") {
                                                let currentDate = new Date();
                                                let targetDate = new Date(stages.endDate);
                                                currentDate.setUTCHours(0, 0, 0, 0);
                                                return (
                                                    <li key={stages.stage.code}>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <button
                                                                    className="pull-right btn btn-outline-primary btn-sm"
                                                                    disabled={ applicationDetails.application && applicationDetails.application.isOptingOut || (!((targetDate.getTime() === currentDate.getTime()) || (targetDate > currentDate)))}
                                                                    onClick={() => gotoApplicationStage(stages, applicationDetails.application.id, applicationDetails.application.advertisement.id)}
                                                                >
                                                                    {common.VIEW}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                         {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((stages) => (
                                            stages && stages.stage && stages.stage.type.toLowerCase() == "internal" && stages.stage.code == "RETOTALING"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => showApplyRetotalingSec((applicationDetails.application && applicationDetails.application), stages)}>
                                                                    {common.VIEW}
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                : null
                                        ))} 
                                        {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((stages) => (
                                            stages && stages.stage && stages.stage.type.toLowerCase() == "internal" && stages.stage.code == "RESULT_MARK_SHEET"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => showMarkSheetModal((applicationDetails.application && applicationDetails.application), stages)}>
                                                                    {common.VIEW}
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                : null
                                        ))}  
                                         {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((stages) => (
                                            stages && stages.stage && stages.stage.type.toLowerCase() == "internal" && stages.stage.code == "PREFERENCE_CHANGE"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                               <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => showPreferenceModal((applicationDetails && applicationDetails.application && applicationDetails.application.applicationNo), stages)}>
                                                                    {common.VIEW}
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                : null
                                        ))}
                                         {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((stages) => (
                                            stages && stages.stage && stages.stage.type.toLowerCase() == "internal" && stages.stage.code == "OPTING_OUT"?                                              
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                               <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => showOptOutModal((applicationDetails && applicationDetails.application && applicationDetails.application.applicationNo), stages)}>
                                                                    {common.VIEW}
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                
                                                : null
                                        ))}
                                         {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((stages) => (
                                            stages && stages.stage && stages.stage.type.toLowerCase() == "internal" && stages.stage.code == "PREF_OPT_OUT"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{stages && stages.stage && stages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                               <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => showPreferenceChangeModal((applicationDetails && applicationDetails.application && applicationDetails.application.applicationNo),stages)}>
                                                                    {common.VIEW}
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                : null
                                        ))}
                                        {(applicationDetails &&  applicationDetails.totalPosts) > (applicationDetails.applicationPostPrefDtoList && applicationDetails.applicationPostPrefDtoList.length) && applicationDetails.application && applicationDetails.application.paymentRefNo ?
                                        <>
                                        {applicationDetails && applicationDetails.advertisementStages && applicationDetails.advertisementStages.map((applicationstages) => (
                                            applicationstages && applicationstages.stage && applicationstages.stage.code == "RECHECK_ELIGIBILITY"  && applicationstages.stage.type !== "external_redirect"&& applicationDetails.application && applicationDetails.application.paymentRefNo ?
                                            <li>
                                            <div className="row">
                                                <div className="col-8">
                                                    <b>{applicationstages && applicationstages.stage && applicationstages.stage.name}</b>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <button className="pull-right btn btn-outline-primary btn-sm" disabled={applicationDetails.application && applicationDetails.application.isOptingOut} onClick={() => reCheckEligibility((applicationDetails.application),applicationstages)}>
                                                        {common.VIEW}
                                                   </button>
                                                </div>
                                            </div>
                                        </li>
                                                : null
                                        ))}  
                                        </>
                                        :null}  
                                        <li>
                                        {applicationDetails && applicationDetails.advertisementStages.filter((appStage) => (appStage.stage.type).toLowerCase() == "internal").map((applicationstages) => (
                                            applicationstages && applicationstages.stage && applicationstages.stage.type.toLowerCase() == "internal" && applicationstages.stage.code == "ANSWER_KEY_QUESTION_OBJECTION"?
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <b>{applicationstages && applicationstages.stage && applicationstages.stage.name}</b>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                               <button type="button" className="pull-right btn btn-outline-primary btn-sm" onClick={()=>{viewObjection(applicationstages)}}>
                                                                    {common.VIEW}
                                                                </button>
                                                               {shwModal && <Objection setShwModal={setShwModal} advId={applicationDetails && applicationDetails.application && applicationDetails.application.id} isReadOnlyObjectionAnswerKeyStage={isReadOnlyObjectionAnswerKeyStage}/>}
                                                            </div>
                                                        </div>
                                                    </li>
                                                : null
                                        ))}  
                                    </li>                                  
                            </ul>      
                    </div>
                </div>
                :""}
                {!profileViewFlag && showApplicationDetailsFlag && isApply ?
                <>
                    { (reCheckEligibilityResultList && reCheckEligibilityResultList.eligibilityResultDtoList != null && applicationPostPrefListForReCheckEligibility && applicationPostPrefListForReCheckEligibility?.length != 0) &&
                            <>
                            <div className="card mt-2">
                                <div className="card-header">
                                    <span className="font-weight-bold">{advertisementDetailsLabel.Select_and_apply}</span><br/>
                                    <hr className="mar_top_bottom_8"/>
                                  
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span>{advertisementDetailsLabel.EligibleFollowingPost}</span>
                                    </li>
                                    { applicationPostPrefListForReCheckEligibility && applicationPostPrefListForReCheckEligibility.map((postPref, index) =>
                                        <li key={index} className="list-group-item post-pref-item" >
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <input className="d-inline-block" type="checkbox" name="isInterested" 
                                                        checked={postPref.isInterested} onChange={(event) => handlePrefChange(postPref, 'isInterested', event.target.checked)} disabled={(postPref.id || postPref.isEligible==false) || isNext || isReadOnlyRecheckEligibilityStage}/> &nbsp;
                                                    <span >{getPostName(postPref.postCriteriaId)}</span> <br /> &nbsp; &nbsp;  &nbsp;
                                                    <span className="w-65">{getPostName(postPref.postCriteriaId, true)}</span>
                                                </div>
                                                <div className="arrange-arrows d-flex flex-column justify-content-between">
                                                    <div>
                                                        { (index > 0) &&
                                                            <i className="fa fa-arrow-up"
                                                                onClick={() => movePostPrefUp(index)}mtitle="Move Up"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        { (index < (applicationPostPrefListForReCheckEligibility.length-1)) &&
                                                            <i className="fa fa-arrow-down"
                                                                onClick={() => movePostPrefDown(index)} title="Move Down"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {!postPref.isEligible && postPref.message?
                                            <>
                                                <span  className="text-danger">{advertisementDetailsLabel.YouAreNotEligiblePost}</span><br/>
                                                <span  className="text-danger">{postPref.message}</span>
                                            </>
                                            :null}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            </>
                        }
                        { (reCheckEligibilityResultList && reCheckEligibilityResultList.eligibilityResultDtoList != null && applicationPostPrefListForReCheckEligibility?.length != 0 && !isNext) &&
                            <>
                                <button type="button" className="btn btn-outline-dark button_reset_apply" onClick={resetPref} disabled={isReadOnlyRecheckEligibilityStage}>{commonApplicationMgmtLabel.RESET}</button>&nbsp;
                                <button type="button" className="btn btn-primary btn button_reset_apply" disabled={(!applyBtnDisable) || (isReadOnlyRecheckEligibilityStage)}  onClick={doNext} >{commonApplicationMgmtLabel.APPLY}</button>
                            </>
                        }
                        { isNext &&
                            <>
                            {uploadedDocumentData && uploadedDocumentData.length >0 ?
                        <div className="card mt-2">
                            <div className="card-header pr-0">
                                <span className="font-weight-bold">{advertisementDetailsLabel.Upload_select_documents} <span className="redColor">*</span></span>
                                <hr className="mar_top_bottom_8"/>
                                <span className="noteColor">{advertisementDetailsLabel.CONFIRM_UPLOAD_DOCUMENT_NOTE}</span>
                            </div>
                            <div className="card-body">
                                <div className="pad_0 btn-dropdown">
                                    {uploadedDocumentData && uploadedDocumentData?.map((doc,i) =>
                                        <li key={i} className="list-group-item post-pref-item d-flex">
                                            <div className="flex-grow-1">
                                                <input className="d-inline-block" type="checkbox" name="isUploaded" checked={doc.isUploaded} 
                                                 onChange={(event) => handleDocChange(doc, 'isUploaded', event.target.checked)}
                                                 disabled={!doc.documentInfoId}
                                               /> &nbsp;

                                               <i className='fa fa-info-circle fs-5 text-primary mr-1 cursor-pointer f18' data-toggle="tooltip" title="Document List"aria-hidden="true" onClick={()=> handleDocumentListShow(doc.documentTypeDescription)} ></i>
                                                <span >{doc.documentTypeName}</span>
                                                <div className="float-right" >
                                                    <input className="d-none" type="file" name="document" id={"document"+i} onChange={(event) => onChangeDocument(event,doc)} accept="application/pdf"/>
                                                    {doc.documentInfoId ?
                                                    <label for={"document"+i}  className="pointer-class">{advertisementDetailsLabel.RE_UPLOAD}</label>
                                                    :
                                                    <label for={"document"+i}  className="pointer-class">{advertisementDetailsLabel.UPLOAD}</label>
                                                    }   
                                                </div> <br />
                                                <div>
                                                {doc.documentInfoId ?
                                                    <button  type="button" className="pointer-class" onClick={(e)=>{
                                                        viewDocument(doc)
                                                    }}>{advertisementDetailsLabel.VIEW_UPLOADED_DOCUMENT}</button>
                                                    :
                                                    <button  type="button" className="text-danger ml37">{advertisementDetailsLabel.NOT_UPLOADED}</button>
                                                }
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </div>
                            </div>
                        </div>:""}
                        <button type="button" className="btn btn-outline-dark btn-sm button_reset_apply" onClick={goBack} >{common.CANCEL}</button>&nbsp;
                        <button type="button" className="btn btn-primary btn-sm button_reset_apply" onClick={onApply} disabled={!(applyBtnDisable)}>{common.SUBMIT}</button>
                        </>}
                        </>
                    :""}

                {profileViewFlag ?
                    <div className="box-two pad_Left_Top_Bottom_10">
                        <button type="button"  className="btn btn-primary btn-sm button_reset_apply" onClick={() =>printProfile()} disabled={(printprofileModule && printprofileModule.isEnabled == false)}>{myaccountlable.PRINT_SAVE_PROFILE}</button>
                        <button type="button" className="btn btn-primary btn-sm " onClick={updateProfileModal}>{common.UPDATE_PROFILE}</button>
                        {printprofileModule && printprofileModule.isEnabled == false ? " " : 
                        <>
                            <h6>{common.PROFILE_STATUS}</h6>
                            <div className="box-contents">
                                <ul className="notype">
                                    <li>
                                        <div className="row">
                                            <div className="col-12">
                                                <b>{common.COMPLETION_STATUS}</b>&nbsp; &nbsp;
                                                {profileStatus ?
                                                    <span className="text-success">{common.COMPLETE}</span> :
                                                    <span className="text-warning">{common.IN_PROGRESS}</span>
                                                }<br />
                                                <b>{common.UPDATE_ON}:</b> &nbsp;<span>{profileSummaryInfo.profile && profileSummaryInfo.profile.updatedAt ? dayjs(profileSummaryInfo.profile.updatedAt).format(common.DATE_FORMAT) : ""}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </> }
                    </div> : ""}
            </div>
                                    </div>
            <UpdateProfile {...props} />
            <ChnagePassword {...props} />
            <CancelApplicationModal 
                showModal={showModal}
                id={myapplication}
                setShowModal = {setShowModal}
                myapplication = {myapplication}
            />
            <ChangeCenter appDetails = {applicationDetails}/>
            <PaymentHistory {...props} />
            <UpdatePostPreference/>
        </div>
        {!profileViewFlag && showApplicationDetailsFlag  ?
        <>
        {viewApplicationModule && viewApplicationModule.isEnabled == false ? 
        <div></div>    
        :
        <div className="row">
            <main className="col-sm-12 col-md-12 col-lg-12">
                <PrintProfileDetails applicationData={currentApplicationData}  applicationPostData = {applicationDetails&& applicationDetails.applicationPostPrefDtoList && applicationDetails.applicationPostPrefDtoList.filter((e=> e.preferredAppointingAuthorities))}/>
                <div className="card advertisement_details_Eligibility border-light">
                    <div className="card-header">
                        {advertisementDetailsLabel.Declaration}
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9">
                                <h6>I&nbsp;
                                {profileDetailsInfo && profileDetailsInfo.personalInfoDto ?
                                        profileDetailsInfo.personalInfoDto.englishFullName
                                        :
                                        "CANDIDATE FULL NAME"
                                    }&nbsp;
                                {advertisementDetailsLabel.DECLARATION}</h6>
                            </div>
                            <div className="col-md-3">
                                <div className="content-row">
                                    <h6>{common.UPDATE_ON}  </h6>
                                    <span>
                                        {applicationDetails && applicationDetails.application && applicationDetails.application.updatedAt
                                            ? dayjs(
                                                applicationDetails.application.updatedAt
                                            ).format(common.DATE_FORMAT)
                                            : ""
                                        }
                                    </span><br />
                                    <h6>{advertisementDetailsLabel.Location}</h6>
                                    <span>
                                        {
                                            applicationDetails.application && applicationDetails.application.location
                                        }
                                    </span>
                                </div><br />
                                {applicationDetails && applicationDetails.signature ?
                                    <img
                                        src={applicationDetails && applicationDetails.signature ? `data:image/png;base64,${profileDetailsInfo.signature}` : ""}
                                        width="130" height="60" />
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
          </main>       
        </div>  }
        </> 
        :""}

        <Modal show={showPaymentModal} onHide={() => { setPaymentModal(false); setShowChallan(true);setShowOnlinePayment(true);setShowSbi(true);setShowBillDesk(true);setShowEaseBuzz(true);setShowQuickwallet(true) }} backdrop='static'>
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{myaccountlable.SELECT_PAYMENT_MODE} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                { showChallan ? 
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymenttype" value="challan_payment" disabled={challanDownloadDate && dayjs(challanDownloadDate).isBefore(new Date(),'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Challan_Payment}
                                    </label>
                                <span className="m17">{advertisementDetailsLabel.DOWNLOAD_AND_PRINT_CHALLAN_NOTE} &nbsp;
                                {challanDownloadDate ? dayjs(challanDownloadDate).format(common.DATE_FORMAT): ""}. {advertisementDetailsLabel.CHALLAN_PAYMENT_NOTE} &nbsp;{showPaymentData?.advertisement?.challanPaymentEndDate ? dayjs(showPaymentData.advertisement.challanPaymentEndDate).format(common.DATE_FORMAT): ""}.
                                </span>
                                </div> : "" }
                                <br />
                                { showOnlinePayment ?
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymenttype" value="online_payment" disabled={showPaymentData.advertisement && dayjs(showPaymentData.advertisement.onlinePaymentEndDate).isBefore(new Date(),'day')}  ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Online_Payment}
                                    </label>
                                    <span className="m17">{advertisementDetailsLabel.ONLINE_PAYMENT_NOTE} &nbsp;
                                    {showPaymentData.advertisement && showPaymentData.advertisement.onlinePaymentEndDate? dayjs(showPaymentData.advertisement.onlinePaymentEndDate).format(common.DATE_FORMAT): ""}
                                    </span>
                                </div> : "" }
                                <br/>
                                {watch("paymenttype") === 'online_payment' ?
                                <div className="pl-3">
                                { showSbi ?    
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymentBank" value="sbi" ref={register({ required: true })} />&nbsp;{common.SBI}
                                    </label>
                                </div> : "" }
                                { showBillDesk ?    
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymentBank" value="billdesk" ref={register({ required: true })} />&nbsp;{common.BILL_DESK}
                                    </label>
                                </div> : "" }
                                { showCsc ? 
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymentBank" value="csc" ref={register({ required: true })} />&nbsp; {common.CSC}
                                    </label>
                                </div> : "" }
                                { showEaseBuzz ?    
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymentBank" value="easebuzz" ref={register({ required: true })} />&nbsp;{common.EASEBUZZ}
                                    </label>
                                </div> : "" }
                                { showQuickwallet ? 
                                <div className="input-group">
                                    <label className="position-relative">
                                        <input type="radio" className="radio" name="paymentBank" value="quickPayWallet" ref={register({ required: true })} />&nbsp; {common.QUICK_WALLET}
                                    </label>
                                </div> : "" }
                                </div>
                                :""}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-primary" 
                        disabled={!(formState.isValid && enablepay) }  onClick={() => { processToPayment(showPaymentData, watch("paymenttype"))}}>{common.OK}</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Modal show={showAdmitCardModal} backdrop='static' size="lg" onHide={() => setShowAdmitCardModal(false)} >
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{common.ADMIT_CARD}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AdmitCard/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary mr-4"  onClick={() => setShowAdmitCardModal(false)}>{common.CANCEL}</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={showAdditionalQuestionsModal} backdrop='static' size="lg" onHide={() => handleCancelAdditionalQuestionsMoal(false)} >
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{advertisementDetailsLabel.QUESTIONS}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="advertisement_details_Eligibility border border-light">
                        <div className="card">
                            <table className="table table-bordered">
                                <thead className="thead-light">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-center w10"
                                    >
                                        {advertisementDetailsLabel.No}
                                    </th>
                                    <th scope="col" className="w70">
                                        {advertisementDetailsLabel.QUESTION_DESCRIPTION}
                                    </th>
                                    <th scope="col" className="w20">
                                        {advertisementDetailsLabel.ANSWER}
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="f12">
                                {applicableAdditionalQuestionsList   &&
                                    applicableAdditionalQuestionsList.map(
                                    (que) => (
                                        <tr key={que.id}>
                                        <td className="w-10 word-break text-center">{++count}</td>
                                        <td  className="word-break">
                                            {que.question}
                                            <span className="redColor">*</span>
                                        </td>
                                        <td className="word-break">
                                            {que.answerType.includes("Text") ||
                                            que.answerType.includes("Date") ||
                                            que.answerType.includes("Number") ? (
                                            <input
                                                type={que.answerType}
                                                className="form-control"
                                                name={que.id}
                                                id={que.id}
                                                value={additionalAnswers[que.id] ?? ''}
                                                onChange={handleQuestions}
                                            />
                                            ) : (
                                            <div>
                                                <label>
                                                <input
                                                    type="radio"
                                                    className="radio"
                                                    name={que.id}
                                                    id={que.id}
                                                    value={true}
                                                    onChange={handleQuestions}
                                                />
                                                &nbsp;{advertisementDetailsLabel.YES}
                                                &nbsp;&nbsp;
                                                </label>
                                                <label>
                                                <input
                                                    type="radio"
                                                    className="radio"
                                                    name={que.id}
                                                    id={que.id}
                                                    value={false}
                                                    onChange={handleQuestions}
                                                />
                                                &nbsp;{advertisementDetailsLabel.NO}
                                                </label>
                                            </div>
                                            )}
                                        </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary mr-2"  onClick={() => handleAddtionalQuestionsSubmit()} disabled={!allIdsAvailable}>{common.SUBMIT}</button>
                        <button type="button" className="btn btn-secondary mr-4"  onClick={() => handleCancelAdditionalQuestionsMoal()}>{common.CANCEL}</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={showMultipleAdmitCardModal} backdrop='static' size="lg" onHide={() => setShowMultipleAdmitCardModal(false)} >
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{common.ADMIT_CARD}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="advertisement_details_Eligibility border border-light">
                        <div className="card">
                            <table className="table table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        {advertisementDetailsLabel.admitCardHeaderContent.map((ele, index) =>
                                            <th scope="col" key={index} >{ele}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="f12">
                                 {examDisplayNameDetails && examDisplayNameDetails.map((ele,i)=>{
                                    if(ele){
                                        return(
                                            <tr>
                                                <td className="word-break">{i+1}</td>
                                                <td className="w-75 word-break">{ele && ele.label ? ele.label : ""}</td>
                                                <td>
                                                    <button className='btn-primary' onClick={(e) => { handleDownloadAdmitCardClick(ele)}}><i className="fa fa-download"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    })} 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary mr-4"  onClick={() => setShowMultipleAdmitCardModal(false)}>{common.CANCEL}</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                
                <Modal show={showMultipleInterviewPtLetterModal} backdrop='static' size="lg" onHide={() => setShowMultipleInterviewPtLetterModal(false)} >
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{common.INTERVIEW_LETTER_EN}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="advertisement_details_Eligibility border border-light">
                        <div className="card">
                            <table className="table table-bordered mb-3">
                                <thead className="thead-light">
                                    <tr>
                                        {advertisementDetailsLabel.interviewPtLetterHeaderContent.map((ele, index) =>
                                            <th scope="col" key={index} >{ele}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="f12">
                                 {downloadInterviewPtLetterData  && downloadInterviewPtLetterData.length > 0 ? downloadInterviewPtLetterData.map((ele,i)=>{
                                    if(ele){
                                        return(
                                            <tr>
                                                <td className="word-break">{i+1}</td>
                                                <td className="w-75 word-break">{ele && ele.interviewPtCsvDto && ele.interviewPtCsvDto.postNameDvng ? ele.interviewPtCsvDto.postNameDvng : ""}</td>
                                                <td>
                                                    <button className='btn-primary' onClick={(e) => { handleDownloadInterviewPTLetterBtnClick(ele, e)}}><i className="fa fa-download"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    })
                                : (
                                    <tr>
                                        <td colSpan="3" className="text-center">{common.NO_DATA_TO_DISPLAY}</td>
                                    </tr>
                                )} 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary mr-4"  onClick={() => setShowMultipleInterviewPtLetterModal(false)}>{common.CANCEL}</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                <DocumentList 
                    showModal = {showDocListModal}
                    setshowDocList = {setshowDocListModal}
                    docData = {singleDocList}
                />
                <MarkSheetModal 
                  showModal = {showMarksheetListModal}
                  setshowMarksheetListModal = {setshowMarksheetListModal}
                  applData={applicationData} 
                  isReadOnlyResultMarkSheetStage={isReadOnlyResultMarkSheetStage}
                />

                <PrefOptingOutModal
                    showModal = {preferenceChanngeModal}
                    setPreferenceChangeModal = {setPreferenceChangeModal} 
                    postPreferenceDetails= {postPreferenceDetails}     
                    applicationNo = {applicationNo}
                    isReadOnlyPrefOptOutStage= {isReadOnlyPrefOptOutStage}
                />

                <PreferenceChangeModal
                    showModal = {preferenceModal}
                    setPreferenceModal = {setPreferenceModal}      
                    postPreferenceDetails= {postPreferenceDetails}     
                    applicationNo = {applicationNo}     
                    isReadOnlyPreferenceChangeStage={isReadOnlyPreferenceChangeStage}
                />

                <OptingOutModal
                    showModal = { optOutModal }
                    setOptOutModal = { setOptOutModal }
                    applicationNo = {applicationNo}
                    isReadOnlyOptingOutStage={isReadOnlyOptingOutStage}
                />
                
                <ViewAppliedDocumentsModal
                    showModal = {appliedDocumentModal}
                    setAppliedDocumentModal={setAppliedDocumentModal}
                    allDepartments={allDepartments}
                />
                {isShowRetotalingForm &&
                <RetotalingModel 
                        showModal = {isShowRetotalingForm}
                        setShowRetotalingForm = {setShowRetotalingForm}
                        isRetotalingRequest= {isRetotalingRequest}
                        applicationData={currentApplicationData} 
                        retotalingPostSubjectsList={retotalingPostSubjectsList} 
                        retotalingRequests = {retotalingRequests}
                        gotoRetotalingPayment = {gotoRetotalingPayment}
                        isReadOnlyRetotalingStage={isReadOnlyRetotalingStage}
                        />
                }
        </div>
    );
};

export default MyAccount;
