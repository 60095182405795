/* eslint-disable */
import { toastr } from "react-redux-toastr";
import { SET_PROFILE_INFO_DISPLAY, SET_MY_APPLICATION_LIST,SET_CHANGE_PASS_MODAL, SET_UPDATE_PROFILE_MODAL,SET_EMAIL_MOBILE_OTP ,SET_VERIFY_OTP_FLAG_EMAIL, SET_VERIFY_OTP_FLAG_MOBILE,
    SET_PROFILE_VIEW ,SET_APPLICATION_DETAILS, SET_SHOW_APPLICATION_DETAILS_FLAG, SET_PRINT_PROFILE,SET_BACK_TO_MY_ACCOUNT,  SET_UPDATE_PROFILE_DONE, SET_PROFILE_SUMMARY_INFO,
    SET_HAS_PENDING_TRANSACTIONS,SET_POST_PREFERENCE_DETAILS, SET_CHANGE_CENTER_FLAG, SET_PAYMENT_HISTORY_LIST , SET_APPLICATION_RE_CHECK_ELIGIBILITY_INFO,SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY, 
    SET_PAYMENT_HISTORY_MODAL, SET_UPDATE_POST_PREF_FLAG, SET_EXAM_CENTER_LIST,SET_MARKSHEET_LIST,  SET_APPLICATION_STAGE_USERDATA,GET_ALL_CANDIDATE_INFO,GET_OBJ_REQ_DATA,GET_DROPDOWN_OBJ_REQ,SET_ALL_OBJREQ,SET_OBJREQ,SET_OBJ_REQ_ID,GET_POST_OBJREP,
    GET_OBJECTION_QUESTIONS, SET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD, SET_DOWNLOAD_INTERVIEW_PT_LETTER_DATA
} from "../../common/constants/ActionTypes";
import { PROFILE_INFO_DISPLAY, APPLICATIONS,API_APPLICATION_RECEIPT , CHANGE_PASSWORD, OTP_FOR_EMAIIL_MOBILE, VERIFYOTP,UPDATE_PROFILE, PROFILE_SUMMARY, CANCEL_APPLICATION,
    HAS_PENDING_TRANSACTIONS,PAYMENTDETAILS,RECHECK_ELIGIBILITY,UPDATEFEESPAIDAPPLICATION, RECONCILE_NOW, GETMARKSHEET, EXAM_CENTER_LIST, USER_APPLICATION_STAGES,
    DOWNLOADMARKSHEET, POST_PREFERENCE_DETAILS, UPDATED_POST_PREFERENCE_DETAILS, OPT_OUT_FROM_APPLICATION,GETADMITCARD,DROPDOWNOBJECTIONREQ,ADDOBJECTIONREQUEST,GETOBJREDBYID,UPDATEOBJREQ,UPLOADIMAGEREQ,MANAGEOBJREQ, GETOBJECTIONQUESTIONS,
    SBIEPAY_OBJECTION_REQUEST, QUIKWALLET_EPAY_OBJECTION_REQUEST, EASEBUZZEPAY_OBJECTION_REQUEST, BILLDESK_OBJECTION_REQUEST, CSC_OBJECTION_REQUEST, SET_ADDITIONAL_QUESTIONS, GET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD, DOWNLOAD_INTERVIEW_PT_LETTER
    } from "../../common/constants/endpoints";
import API from "../../helpers/API";
import axios from "axios";
import FileDownload from "js-file-download";
import { getProfileStatus } from "../../candidate_profile_creation/actions/profileAction";
import { logout } from "../../registration_login/actions/sessionActions";
import { formPost } from "../../helpers/util"
import { hideLoader, showLoader } from "../../common/actions/commonActions";
import 'jspdf-autotable';
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../labels/common_en.json";
import RegistrationJson from "../../registration_login/labels/registration_en.json";

export const getPrfileSummaryDetails = (profileId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        if (!profileId) {
            profileId = session.profileId;
        }
        let response;
        try {
            if(profileId){
            response = await API.get(PROFILE_SUMMARY + `/${profileId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PROFILE_SUMMARY_INFO,
                payload: response.data,
            });
        }
        else
        {
            dispatch(hideLoader());
        }
        } catch (err) {
                dispatch(hideLoader());
            toastr.error(advertisementDetailsLabel.ERR_GETTING_PROFILE_INFO);
            return;
        }
    };
};

export const getProfileInfoDisplay = (profileId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        if (!profileId) {
            profileId = session.profileId;
        }
        let response;
        try {
            response = await API.get(PROFILE_INFO_DISPLAY + `/${profileId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_PROFILE_INFO_DISPLAY,
                payload: response.data,
            });
        } catch (err) {
                dispatch(hideLoader());
            toastr.error(advertisementDetailsLabel.ERR_GETTING_PROFILE_INFO);
            return;
        }
        
    };
};

export const getMyApplications = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response = await API.get(APPLICATIONS, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        processMyApplicationsResponse(response, dispatch);
    };
};

export const getPostPreferenceDetails = (appNo) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(POST_PREFERENCE_DETAILS + `/${appNo}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            dispatch({
                type: SET_POST_PREFERENCE_DETAILS,
                payload: response.data,
            });
        } catch (err) {
                dispatch(hideLoader());
            return;
        }
    };
};

export const  setUpdatedPostPreferenceDetails = (appNo, reqData,otpId)=>{
    return async (dispatch) => {
        dispatch(showLoader());
      let response;
      let res = {
        reqData: reqData,
        otpData: otpId
      }
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.post(UPDATED_POST_PREFERENCE_DETAILS+`/${appNo}`, res,{
            headers: { Authorization: `Bearer ${token}` }
        }); 
        if(response.status===200){
            toastr.success(commonLabels.POST_PREFERENCE_UPDATED_SUCCESSFULLY);
            window.location.reload();
        };
        if(response!=null)
        {
            dispatch(hideLoader());
        };
        } catch(error){
             dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const optOutFromApplication = (appNo,otpData)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.patch(OPT_OUT_FROM_APPLICATION + `/${appNo}`,otpData, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response.status===200){
                toastr.success(commonLabels.OPTING_OUT_SUCCESSFULLY);
                window.location.reload();
            };
            if(response!=null)
            {
                dispatch(hideLoader());
            };
        } catch (error) {
          dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

const processMyApplicationsResponse = (response, dispatch) => {
    if (response && response.data) {
        let myApplicationList = response.data;
        dispatch({
            payload: myApplicationList,
            type: SET_MY_APPLICATION_LIST,
        });
        dispatch(getHasPendingTransactions(myApplicationList));
    }
};

export const getHasPendingTransactions = (myApplicationList) => {
    return async (dispatch) => {
        dispatch(showLoader());
        const applicationIds = myApplicationList
            .filter(application => (!application.application.isDeleted) && (!application.application.paymentRefNo || application.application.paymentRefNo.trim() === ''))
            .map(application => application.application.id);
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response = await API.get(HAS_PENDING_TRANSACTIONS, {
            headers: { Authorization: `Bearer ${token}` },
            params: { applicationIds: applicationIds.join(',') },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            type: SET_HAS_PENDING_TRANSACTIONS,
            payload: response.data
        });
    }
};

export const reconcileNow = (applicationIds) => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(RECONCILE_NOW, {
                headers: { Authorization: `Bearer ${token}` },
                params: { applicationIds: applicationIds.join(',') },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = error.response?.data?.message ?? error.message
            toastr.error(errorMessage);
            return;
        }

        let isToastrShown = false;
        if (response.data.numSuccess > 0) {
            const isSingle = response.data.numSuccess === 1;
            toastr.success(`${isSingle ? 'The' : response.data.numSuccess} transaction${isSingle ? ' was' : 's were'} successfully reconciled`);
            isToastrShown = true;
        }
        if (response.data.numAwaiting > 0) {
            const isSingle = response.data.numAwaiting === 1;
            toastr.info(`${isSingle ? 'The' : response.data.numAwaiting} transaction's status${isSingle ? ' is' : 'es are'} still awaiting`);
            isToastrShown = true;
        }
        (response.data.errorMessages ?? []).forEach(errorMessage => {
            toastr.error(errorMessage);
            isToastrShown = true;
        });

        if (!isToastrShown) {
            toastr.info(commonLabels.NO_TRANSACTION_STATUS_UPDATE_AVAILABLE);
        }
        // Get list of my applications again if there were some success or failure transactions updated
        if (response.data.numSuccess > 0 || response.data.numFailure > 0) {
            dispatch(getMyApplications());
        }
    }
};

export const setChangePasswordFlg = (flag) =>{
    return async (dispatch) => {
    dispatch({
        payload: flag,
        type: SET_CHANGE_PASS_MODAL,
    });
    }
};

export const showListMarksheetModalAction = (applicationId) =>{
    return async(dispatch)=>{
        dispatch(showLoader());
        let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try {
            if(applicationId){
            response = await API.get(GETMARKSHEET + `/${applicationId}`, { headers: { 'Authorization': `Bearer ${token}` } });
           if(response.status === 200){
               dispatch({
                   payload: response.data,
                   type : SET_MARKSHEET_LIST
               })
           }
           if(response!=null)
           {
               dispatch(hideLoader());
           }
        }
        else
        {
            dispatch(hideLoader());
        }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const downloadMarksheet = (applicationMarksId) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url: DOWNLOADMARKSHEET+'/'+applicationMarksId,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
             dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(
            response.data,
            `MarkSheet_${applicationMarksId}.html`
        );
    };
};

export const ChangePasswordRequest = (data,props, navigate)=>{
    return async (dispatch) => {
        dispatch(showLoader());
      let response;
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.post(CHANGE_PASSWORD, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: false,
            type: SET_CHANGE_PASS_MODAL,
        });
        if(response.status === 200) {
            toastr.success(response.data)
            dispatch(logout(props, '', navigate));
        }
        } catch(error){
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const setProfileUpdateCloseModal=(flag)=>{
    return async(dispatch)=>{
        dispatch({
            payload: flag,
            type: SET_UPDATE_PROFILE_MODAL,
        });
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_EMAIL
            })
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_MOBILE
            })
    }
};

export const setProfileViewFlg = (flag)=>{
    return async(dispatch)=>{
        dispatch({
            payload:flag,
            type:SET_PROFILE_VIEW
        })
    }
};

export const setUpdateProfileflg = (flag, sendOTPflag, props, navigate)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
    // dispatch({
    //     payload: flag,
    //     type: SET_UPDATE_PROFILE_MODAL,
    // });
    if(!sendOTPflag){
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.get(OTP_FOR_EMAIIL_MOBILE+`/Unlock Profile`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        if((response) && (response.data) && (response.data.length === 0)){
            dispatch(updateProfileRequest(props, navigate))
        }else{
            dispatch({
                payload: flag,
                type: SET_UPDATE_PROFILE_MODAL,
            });
        }
        dispatch({
            payload: response.data,
            type: SET_EMAIL_MOBILE_OTP,
        });
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_EMAIL
        })
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_MOBILE
        })         
        } catch(error){
           dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }   
    }else{
        dispatch({
            payload: flag,
            type: SET_UPDATE_PROFILE_MODAL,
        });
    } 
    }
};

export const verificationOfOTP = (reqBody, type) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try{
        let response = await API.post(VERIFYOTP,  reqBody);
        if(response.data){
            toastr.success(RegistrationJson.OTP_VERIFICATION_SUCCESSFUL);
        } else{
            toastr.error(RegistrationJson.OTP_VERIFICATION_UNSUCCESSFUL);
        }
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        if(type === 'email'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_EMAIL
            })
        } 
        if(type === 'mobile'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_MOBILE
            })
        }
    }catch (error) {
            dispatch(hideLoader());
        if (error && error.response) {
            let errorMessage = (error && error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    }
    }
};

export const updateProfileRequest = (props, navigate)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
        let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let profileId = session.profileId;
        let userId = session.userId;
        try {
            response = await API.patch(UPDATE_PROFILE + `/${userId}/${profileId}`, {}, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response.status === 200) {
                toastr.success(commonLabels.PROFILE_UPDATED_OR_UNLOCK_SUCCESSFULLY)
                // props.history.push("/candidate/main");
                navigate("/candidate/main")
                dispatch({
                    payload: false,
                    type: SET_UPDATE_PROFILE_MODAL,
                });
                dispatch(getProfileStatus());
                dispatch({
                    payload: true,
                    type: SET_UPDATE_PROFILE_DONE,
                });
                dispatch({
                    payload:false,
                    type:SET_PROFILE_VIEW
                })
                dispatch({
                    payload: false,
                    type: SET_VERIFY_OTP_FLAG_EMAIL
                })
                dispatch({
                    payload: false,
                    type: SET_VERIFY_OTP_FLAG_MOBILE
                })
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            } catch(error){
                dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }
    }
};

export const setShowApplicationDetails = (applicationId) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try {
            response = await API.get(APPLICATIONS + `/${applicationId}/`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response && response.data && response.data.application && response.data.application.isOptingOut){
                toastr.error(commonLabels.YOU_HAVE_BEEN_OPT_OUT_FROM_APPLICATION);
            }
            if(response.status===200){
                dispatch({
                    payload:response.data,
                    type:SET_APPLICATION_DETAILS
                })
                dispatch({
                    payload:true,
                    type:SET_SHOW_APPLICATION_DETAILS_FLAG
                })
                dispatch(setuserDataForApplicationStage());
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            } catch(error){
                dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }
    }
};

export const setShowApplicationReceipt = (applicationId) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url: API_APPLICATION_RECEIPT + applicationId,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(
            response.data,
            `Receipt_Details_${applicationId}.html`
        );
    };
};

export const downloadAdmitCard = (advertisementStage) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url: advertisementStage.stage.url + advertisementStage.advertisementId,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(
            response.data,
            `Admit_Card_${advertisementStage.advertisementId}.pdf`
        );
    };
};

export const cancelApplication = (applicationId , reqbody)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.patch(CANCEL_APPLICATION + `/${applicationId}`,reqbody, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response.status===200){
            dispatch(getMyApplications());
            dispatch({
                payload:false,
                type:SET_SHOW_APPLICATION_DETAILS_FLAG
            })
            toastr.success(commonLabels.YOUR_APPLICATION_IS_CANCELLED_SUCCESSFULLY);
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
          dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

 export const setPrintProfile = (flg)=>{
    return async(dispatch)=>{
        dispatch({
            payload:flg,
            type:SET_PRINT_PROFILE
        })
    }
 };

 export const setApplicationDetailsFlg = (flg)=>{
    return async(dispatch)=>{
        dispatch({
            payload:flg,
            type:SET_SHOW_APPLICATION_DETAILS_FLAG
        })
    }
 };

 export const backToMyAccount= (flg)=>{
    return async(dispatch)=>{
        dispatch({
            payload:flg,
            type:SET_BACK_TO_MY_ACCOUNT
        })
    }
 };

 export const cancelApplicationOTP = ()=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.get(OTP_FOR_EMAIIL_MOBILE+`/Cancel Application`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: response.data,
            type: SET_EMAIL_MOBILE_OTP,
        });
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_EMAIL
        })
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_MOBILE
        })        
        } catch(error){
           dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }    
    }
};

export const setPaymentHistoryModal = (flag,applID) => {
    return async(dispatch) => {
        dispatch(showLoader());
        try{
            dispatch({
                type :  SET_PAYMENT_HISTORY_MODAL,
                payload : flag
            })
            let response;
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            if(applID){
                response = await API.get(PAYMENTDETAILS+`${applID}`, { headers: { 'Authorization': `Bearer ${token}` } });
                    if(response.status===200){
                        dispatch({
                            payload:response.data,
                            type:SET_PAYMENT_HISTORY_LIST
                        })
                    } 
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }  
            } 
            else
            {
                dispatch(hideLoader());
            }
            if(flag === false){
                dispatch({
                    payload:[],
                    type:SET_PAYMENT_HISTORY_LIST
                })
            }
        }catch (error) {
             dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const setChangeCenterModal = (flag ,advtID, applicationId) => {
    return async(dispatch) => {
        dispatch(showLoader());
        dispatch({
            type :  SET_CHANGE_CENTER_FLAG,
            payload : flag
        })
        let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        if(advtID){
            response = await API.get(EXAM_CENTER_LIST + `/${advtID}/${applicationId}`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
                if(response.status===200){
                 const examCenterList = response.data?.map(
                        (center) => ({
                            value: center.examCenter.id,
                            label: center.examCenter.name,
                            isDeleted: center.isDeleted
                        })
                    );
                        dispatch({
                            payload:examCenterList,
                            type:SET_EXAM_CENTER_LIST
                        })
                } 
        }   
        else
        {
            dispatch(hideLoader());
        }      
    }
};

export const setUpdatePostModal = (flag) => {
    return async(dispatch) => {
        dispatch({
            type :  SET_UPDATE_POST_PREF_FLAG,
            payload : flag
        })
    }
};

export const updateExamCenter = (centerData) => {
    return async(dispatch) => {
        dispatch(showLoader());
     let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try{
            response = await API.post(APPLICATIONS + `/${centerData.applicationId}/`+ "changeexamcenter", centerData,
             { headers: { 'Authorization': `Bearer ${token}` } });
             if(response!=null)
             {
                 dispatch(hideLoader());
             }
            if(response.status === 200){
                toastr.success(response.data);
                const responseOfApplication = await API.get(APPLICATIONS + `/${centerData.applicationId}/`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(responseOfApplication.status==200){
                        dispatch({
                            payload:responseOfApplication.data,
                            type:SET_APPLICATION_DETAILS
                        })
                }
            }
        }catch(error){
             dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }        
    }
};

export const updatePostPreference = (reqBody) => {
    return async(dispatch) => {
        dispatch(showLoader());
   let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try{
            response = await API.patch(APPLICATIONS + `/${reqBody.applicationId}/`+ "postpreforder" , reqBody, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if(response.status === 200){
                toastr.success(response.data);
                const responseOfApplication = await API.get(APPLICATIONS + `/${reqBody.applicationId}/`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(responseOfApplication.status==200){
                        dispatch({
                            payload:responseOfApplication.data,
                            type:SET_APPLICATION_DETAILS
                        })
                }
            }
        }catch(error){
             dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }                
    }
};

export const reCheckEligibilityAction = (applicationId) => {
    return async(dispatch) => {
        dispatch(showLoader());
   let response;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try{
            response = await API.get(RECHECK_ELIGIBILITY, {
                 params: { applicationId },
                 headers: { 'Authorization': `Bearer ${token}` } });
                 if(response!=null)
                 {
                     dispatch(hideLoader());
                 }
            if(response.status === 200){
                dispatch({
                    type: SET_APPLICATION_RE_CHECK_ELIGIBILITY_INFO,
                    payload: response.data,
                });
                let postAppliedArray = response.data.postApplied
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    id:elgbResult.id,
                    isInterested: true
                }))
                let postNotAppliedArray = response.data.eligibilityResultDtoList
                .filter(el => response.data.postNotApplied.includes(el.postCriteriaId))
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    isEligible:elgbResult.isEligible,
                    isInterested: false,
                    message:elgbResult.message
                }))
               let postArray = [...new Set([...postAppliedArray ,...postNotAppliedArray])]
                dispatch({
                    type: SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY,
                    payload: postArray 
                });
            }
        }catch(error){
            dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }                
    }
};

export const resetApplicationPostPrefListForReCheckEligibility = () => {
    return (dispatch, getState) => {
        const { reCheckEligibilityResultList } = getState().myaccount;
        let postAppliedArray = reCheckEligibilityResultList.postApplied
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    id:elgbResult.id,
                    isInterested: true
                }))
        let postNotAppliedArray = reCheckEligibilityResultList.eligibilityResultDtoList
                .filter(el => reCheckEligibilityResultList.postNotApplied.includes(el.postCriteriaId))
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    isEligible:elgbResult.isEligible,
                    isInterested: false,
                    message:elgbResult.message
                }))
        let postArray = [...new Set([...postAppliedArray ,...postNotAppliedArray])]
        dispatch({
            type: SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY,
            payload: postArray
        });
    };
};

export const  updateFeesPaidApplication = (applnData)=>{
    return async (dispatch) => {
        dispatch(showLoader());
      let response;
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.post(UPDATEFEESPAIDAPPLICATION, applnData, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload:response.data,
            type:SET_APPLICATION_DETAILS
        })
        toastr.success(commonLabels.RE_APPLIED_FOR_POST_SUCCESSFULLY);
        } catch(error){
             dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const externalApplicationStage = (advertisementStage, userData) => {
    return async (dispatch) => {
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            userData.jwt = token
            dispatch(showLoader());
            formPost( advertisementStage.stage.url, userData)
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    };
};

export const setuserDataForApplicationStage = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let responseForApplicationStage;
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        try {
            responseForApplicationStage = await API.get( USER_APPLICATION_STAGES , { headers: { 'Authorization': `Bearer ${token}` } } );
            if(responseForApplicationStage.status === 200){
                dispatch({
                    payload : responseForApplicationStage.data,
                    type : SET_APPLICATION_STAGE_USERDATA
                })
            }
            if(responseForApplicationStage!=null)
            {
                dispatch(hideLoader());
            }
            } catch(error){
                  dispatch(hideLoader());
                let errorMessage = (error.response && error.response.data) ?
                error.response.data.message: error.message
                toastr.error(errorMessage);
                return;
            }
    }
};

export const lockPreferenceOtp = ()=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.get(OTP_FOR_EMAIIL_MOBILE+`/PREFCHANGE`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: response.data,
            type: SET_EMAIL_MOBILE_OTP,
        });
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_EMAIL
        })
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_MOBILE
        })        
        } catch(error){
           dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }    
    }
};

export const optingOutOtp = ()=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
      try {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        response = await API.get(OTP_FOR_EMAIIL_MOBILE+`/OPTOUT`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: response.data,
            type: SET_EMAIL_MOBILE_OTP,
        });
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_EMAIL
        })
        dispatch({
            payload: false,
            type: SET_VERIFY_OTP_FLAG_MOBILE
        })        
        } catch(error){
           dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }    
    }
};

export const admitCard = (id, tempid) => {
    return async function (dispatch) {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let token = session.jwtResponse && session.jwtResponse.token;
      let response;
      let isSuccess = true;
      try {
        if(id && tempid){
            response = await API.get(GETADMITCARD + `?id=${id}` + `&templateid=${tempid}`, {
                headers: { Authorization: `Bearer ${token}` },
              });      
        }else{
            response = await API.get(GETADMITCARD + `?id=${id}`, {
                headers: { Authorization: `Bearer ${token}` },
              });      
        }

        dispatch({
          type: GET_ALL_CANDIDATE_INFO,
          payload: response.data,
        });
        return response;
      } catch (error) {
        isSuccess = false;
        let errorMessage = (error.response && error.response.data) ? error.response.data.message : error.message;
        toastr.error(errorMessage);
        throw error; 
      }
    };
};

export const dropdownDataObjReq = (advid,stgid)=>{
return async function(dispatch){
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token;
    let response;
    try{
        response = await API.get(DROPDOWNOBJECTIONREQ+`?advid=${advid}`+`&stgid=${stgid}`,{
            headers: { Authorization: `Bearer ${token}` }});
            dispatch({
                type:GET_DROPDOWN_OBJ_REQ,
                payload:response.data
            })
    }catch(error){
        dispatch(hideLoader());
        let errorMessage = (error.response && error.response.data) ?
        error.response.data.message: error.message
        toastr.error(errorMessage);
        return;
    }
}
};

export const addObjectionReq=(reqBody)=>{
return async function(dispatch){
    dispatch(showLoader());
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token;
    let response;
    let formData = new FormData();
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.applicationId){
        formData.append('applicationId', reqBody.objectionRequestsDto.applicationId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.advtId){
        formData.append('advtId', reqBody.objectionRequestsDto.advtId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examId){
        formData.append('examId', reqBody.objectionRequestsDto.examId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectId){
        formData.append('examSubjectId', reqBody.objectionRequestsDto.examSubjectId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examPaperSet){
        formData.append('examPaperSet', reqBody.objectionRequestsDto.examPaperSet);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.objectionType){
        formData.append('objectionType', reqBody.objectionRequestsDto.objectionType);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.candidateSetQuestionSrno){
        formData.append('candidateSetQuestionSrno', reqBody.objectionRequestsDto.candidateSetQuestionSrno);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.candidateAnswer){
        formData.append('candidateAnswer', reqBody.objectionRequestsDto.candidateAnswer);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.answerInAnswerKey){
        formData.append('answerInAnswerKey', reqBody.objectionRequestsDto.answerInAnswerKey);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.objectionRemark){
        formData.append('objectionRemark', reqBody.objectionRequestsDto.objectionRemark);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceBook){
        formData.append('referenceBook', reqBody.objectionRequestsDto.referenceBook);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.authorName){
        formData.append('authorName', reqBody.objectionRequestsDto.authorName);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.applicationNo){
        formData.append('applicationNo', reqBody.objectionRequestsDto.applicationNo);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectCode){
        formData.append('examSubjectCode', reqBody.objectionRequestsDto.examSubjectCode);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceDocumentFile){
        formData.append('referenceDocumentFile', reqBody.objectionRequestsDto.referenceDocumentFile);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceDocumentFile2){
        formData.append('referenceDocumentFile2', reqBody.objectionRequestsDto.referenceDocumentFile2);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectName){
        formData.append('examSubjectName', reqBody.objectionRequestsDto.examSubjectName);
    }
    let header = {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData",
    }
    try{
            response = await API.post(ADDOBJECTIONREQUEST, formData, {
                headers: header,
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            let successMessage = (response && response.data) ? response.data.message : response.message
            toastr.success(successMessage);
            dispatch(getObjReqData(reqBody.objectionRequestsDto.applicationId))
            dispatch({
                type: GET_POST_OBJREP,
                payload:response.data
            })
    }catch(error){
        dispatch(hideLoader());
        let errorMessage = (error.response && error.response.data) ?
        error.response.data.message: error.message
        toastr.error(errorMessage);
        dispatch(getObjReqData(reqBody.objectionRequestsDto.applicationId))
        return; 
    }
}
};

export const editObjectionReq=(id,reqBody,setAddObjection)=>{
return async function(dispatch){
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token;
    let response;
    let formData = new FormData();
    if(id){
        formData.append('id', id);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.applicationId){
        formData.append('applicationId', reqBody.objectionRequestsDto.applicationId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.advtId){
        formData.append('advtId', reqBody.objectionRequestsDto.advtId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examId){
        formData.append('examId', reqBody.objectionRequestsDto.examId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectId){
        formData.append('examSubjectId', reqBody.objectionRequestsDto.examSubjectId);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examPaperSet){
        formData.append('examPaperSet', reqBody.objectionRequestsDto.examPaperSet);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.objectionType){
        formData.append('objectionType', reqBody.objectionRequestsDto.objectionType);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.candidateSetQuestionSrno){
        formData.append('candidateSetQuestionSrno', reqBody.objectionRequestsDto.candidateSetQuestionSrno);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.candidateAnswer){
        formData.append('candidateAnswer', reqBody.objectionRequestsDto.candidateAnswer);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.answerInAnswerKey){
        formData.append('answerInAnswerKey', reqBody.objectionRequestsDto.answerInAnswerKey);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.objectionRemark){
        formData.append('objectionRemark', reqBody.objectionRequestsDto.objectionRemark);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceBook){
        formData.append('referenceBook', reqBody.objectionRequestsDto.referenceBook);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.authorName){
        formData.append('authorName', reqBody.objectionRequestsDto.authorName);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.applicationNo){
        formData.append('applicationNo', reqBody.objectionRequestsDto.applicationNo);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectCode){
        formData.append('examSubjectCode', reqBody.objectionRequestsDto.examSubjectCode);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceDocumentFile){
        formData.append('referenceDocumentFile', reqBody.objectionRequestsDto.referenceDocumentFile);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.referenceDocumentFile2){
        formData.append('referenceDocumentFile2', reqBody.objectionRequestsDto.referenceDocumentFile2);
    }
    if(reqBody && reqBody.objectionRequestsDto && reqBody.objectionRequestsDto.examSubjectName){
        formData.append('examSubjectName', reqBody.objectionRequestsDto.examSubjectName);
    }
    let header = {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData",
    }
    try{
        response=await API.patch(UPDATEOBJREQ,formData,{
            headers: header
        });
        let successMessage = (response && response.data) ? response.data.message : response.message
        toastr.success(successMessage);
            dispatch(resetObjReq());
            dispatch(resetObjReqList());
            setAddObjection({"Mode":false});
            dispatch(getObjReqData(reqBody.objectionRequestsDto.applicationId))
    }catch(error){
        let errorMessage = (error.response && error.response.data) ?
        error.response.data.message: error.message
        toastr.error(errorMessage);
        dispatch(getObjReqData(reqBody.objectionRequestsDto.applicationId))
        return; 
    }
}
};

export const getObjReqData=(applicationId)=>{
return async function(dispatch){
    dispatch(showLoader());
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token;
    let response;
    try{
        response=await API.get(GETOBJREDBYID+`/${applicationId}`,{
        headers: { Authorization: `Bearer ${token}` }});
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            type : GET_OBJ_REQ_DATA,
            payload: response.data
            });
    }catch(error){
        dispatch(hideLoader());
        let errorMessage = (error.response && error.response.data) ?
        error.response.data.message: error.message
        toastr.error(errorMessage);
        return; 
    }
}
};

export const resetObjReq=()=>{
return async (dispatch)=>{
    dispatch({
        type: SET_OBJREQ,
        payload: {},
    });
}
};

export const resetObjReqList=()=>{
    return async (dispatch)=>{
        dispatch({
            type: SET_ALL_OBJREQ,
            payload: {},
        });
    }
};

export const getObjReqDataById = (id)=>{
    return async (dispatch)=>{
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try{
            response = await API.get(GETOBJREDBYID+`/?id=${id}`,{
                headers: { Authorization: `Bearer ${token}` }});
                dispatch({
                    type:SET_OBJ_REQ_ID,
                    payload:response.data
                })
        }catch(error){
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const uploadReqImage = (data)=>{
    return async (dispatch)=>{
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        let formData = new FormData();
    if (data.refDoc) {
        formData.append('referenceDocumentFile', data.refDoc[0]);
    }
    if(data.objId){
        formData.append('id',data.objId);
    }
    if(data.appNo){
        formData.append('applicationNo',data.appNo);
    }
    if(data.examSubCode){
        formData.append('examSubjectCode',data.examSubCode);
    }
    if( data.masterQueSrNo){
        formData.append('masterQuestionSrno',data.masterQueSrNo);
    }
        try{
            response = await API.post(UPLOADIMAGEREQ,{
                headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "type": "formData" }});
                toastr.success(response.data.message);
        }catch(error){
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const manageObjReq = (reqbody,applicationId)=>{
    return async (dispatch)=>{
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        let header = {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
        }
        try{
            response = await API.patch(MANAGEOBJREQ,reqbody,{headers: header});
            dispatch(getObjReqData(applicationId))
            toastr.success(response.data.message);
        }catch(error){
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message : error.message
        toastr.error(errorMessage);
        dispatch(getObjReqData(applicationId))
        return;
        }
    }
};

export const getObjectionQuestions = (paperset,exid,esubid,advid)=>{
return async function(dispatch){
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token;
    let response;
    try{
        response = await API.get(GETOBJECTIONQUESTIONS+`?paperset=${paperset}`+`&exid=${exid}`+`&esubid=${esubid}` +`&advid=${advid}`,{
            headers: { Authorization: `Bearer ${token}` }});
            dispatch({
                type:GET_OBJECTION_QUESTIONS,
                payload:response.data
            })
    }catch(error){
        dispatch(hideLoader());
        let errorMessage = (error.response && error.response.data) ?
        error.response.data.message: error.message
        toastr.error(errorMessage);
        return;
    }
}
};

  export const doObjectionRequestPayment = (objectionReqId,paymentType, data, requestBody) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            if(paymentType=='online_payment'){
                if (data.paymentBank == 'sbi') {
                    //for sbi
                    response = await API.post(SBIEPAY_OBJECTION_REQUEST + objectionReqId, requestBody, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    let paymentUrl;
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    if (response.data.isProdEnv) {
                        paymentUrl = `https://www.sbiepay.sbi/secure/AggregatorHostedListener`;
                    } else {
                        paymentUrl = `https://test.sbiepay.sbi/secure/AggregatorHostedListener`;
                    }
                    formPost(paymentUrl, {
                        EncryptTrans: response.data.encryptTrans,
                        merchIdVal: response.data.merchantId,                         
                    });
                }
                    //for QuikWallet
               else if(data.paymentBank == 'quickPayWallet') {
                    // for quickPayWallet
                    response = await API.post(QUIKWALLET_EPAY_OBJECTION_REQUEST + objectionReqId, requestBody, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    window.location.href = response.data.url;
                } 
                else if(data.paymentBank == 'easebuzz') {
                    // for easebuzz
                    response = await API.post(EASEBUZZEPAY_OBJECTION_REQUEST + `${objectionReqId}`, requestBody, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    window.location.href =response?.data;
                }
                else if(data.paymentBank == 'billdesk') {
                    // for billdesk
                    response = await API.post(BILLDESK_OBJECTION_REQUEST + `${objectionReqId}`, requestBody, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    let isProdEnv = response.data.isProdEnv;
                    let paymentMes = response.data.msg;
                    if(!isProdEnv){
                    let paymentUrl;
                        paymentUrl = `https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }else{
                        let paymentUrl;
                        paymentUrl = `https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }
                }
                else {
                    //for csc
                    response = await API.post(CSC_OBJECTION_REQUEST + objectionReqId, requestBody, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    let paymentUrl;
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    if(response.data.isProdEnv){
                        paymentUrl = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }else{
                        paymentUrl = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }
                    window.location.href = paymentUrl;
            }
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const setAdditonalQuestions = (appid, reqbody)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.patch(SET_ADDITIONAL_QUESTIONS +`?appid=${appid}`,reqbody, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
                toastr.success(response.data)
                dispatch(getMyApplications());
            };
           
        } catch (error) {
          dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const getExamDisplayNameDetailsForAdmitCard = (examid)=>{
    return async function(dispatch){
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try{
            response = await API.get(GET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD + `?examid=${examid}`,{
                headers: { Authorization: `Bearer ${token}` }});
                dispatch({
                    type:SET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD,
                    payload:response.data
                })
        }catch(error){
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
    };

    export const downloadInterviewPTLetterAPI = (applnid) => {
        return async function (dispatch) {
            dispatch(showLoader());
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let response;
            try {
                response = await API.get(DOWNLOAD_INTERVIEW_PT_LETTER + `?applnid=${applnid}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
                dispatch({
                    type: SET_DOWNLOAD_INTERVIEW_PT_LETTER_DATA,
                    payload: response.data,
                });
            } catch (err) {
                    dispatch(hideLoader());
                return;
            }
            
        };
    };