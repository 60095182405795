import API from '../../helpers/API';
import CryptoJS from 'crypto-js';
import { SET_CAPTCHA_IMAGE, SET_GET_EMAIL_OTP_RESPONSE, SET_GET_MOBILE_OTP_RESPONSE, SHOW_LOADER, HIDE_LOADER, SET_VERIFY_OTP_FLAG_LOGIN, SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD, SET_GET_USERNAME_OTP_RESPONSE, 
    SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL,SET_VERIFY_OTP_FLAG_FORGOT_EMAIL, SET_VERIFY_OTP_FLAG_FORGOT_MOBILE, SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE, SET_GUIDELINE_LINKS, SET_MODULE_ENABLE_DISABLE,
    SET_API_CALL_FOR_MODULE, GET_OTP_DATA_EMAIL,GET_OTP_DATA_MOBILE,GET_OTP_DATA_USERNAME, SET_IS_MOBILE_NO_ALREADY_EXIST_FLG, SET_IS_EMAIL_ID_ALREADY_EXIST_FLG} from '../../common/constants/ActionTypes';
import { OTPS, VERIFYOTP, GUIDELINELINKS, MODULE_CHECK,EASEBUZZ_CALLBACK } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';
import FileDownload from "js-file-download";
import commonLabels from "../../my_account/labels/common_en.json";
import registrationLabels from "../../registration_login/labels/registration_en.json";
import {ENCR_DECR_KEY} from '../../configuration';

const decryptKey=CryptoJS.enc.Utf8.parse(ENCR_DECR_KEY);
const decryptIV = CryptoJS.enc.Utf8.parse(ENCR_DECR_KEY);
export function encryptData(data) {
    try {
        const encrypted = CryptoJS.AES.encrypt(data, decryptKey, {
            iv: decryptIV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    } catch (error) {
        console.error("Encryption failed:", error);
        return null;
    }
}

export function decryptData(encryptedData) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, decryptKey, {
            iv: decryptIV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
}

export const generateCaptcha = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.post(OTPS, {
                "type": "captcha",
                
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                let captchaId = response.data.id;
                dispatch({
                    payload: captchaId,
                    type: SET_CAPTCHA_IMAGE
                });
            }
        } catch (error) {
            dispatch(hideLoader());
            toastr.error(commonLabels.ERROR_WHILE_GENERATING_CAPTCHA_IMAGE, error.message);
        }
    }
};

export const generateOTPForgotPassword = (otpDetails) => {
        return async (dispatch) => {
            dispatch(showLoader());
           try {
              let request;
              switch (otpDetails.type) {
                  case 'email':
                      if (otpDetails.email === "") {
                        dispatch(hideLoader());
                          toastr.info(commonLabels.ENTER_VALID_EMAIL_ID);
                          return
                      }
                      request = {
                          "email": otpDetails.email,
                          "mobile": "",
                          "username": "",
                          "type": otpDetails.type,
                          "otpFor": otpDetails.otpFor,
                      }
                      break;
                  case 'mobile':
                      if (otpDetails.mobile === "") {
                        dispatch(hideLoader());
                          toastr.info(commonLabels.ENTER_VALID_MOBILE_NUMBER);
                          return
                      }
                      request = {
                          "email": "",
                          "mobile": otpDetails.mobile,
                          "username": "",
                          "type": otpDetails.type,
                          "otpFor": otpDetails.otpFor
                      }
                      break;
                  case 'username':
                      if (otpDetails.username === "") {
                        dispatch(hideLoader());
                          toastr.info(commonLabels.ENTER_VALID_USERNAME);
                          return
                      }
                      request = {
                          "email": "",
                          "mobile": "",
                          "username": otpDetails.username,
                          "type": otpDetails.type,
                          "otpFor": otpDetails.otpFor
                      }
                      break;
                  default:
                    dispatch(hideLoader());
                      toastr.info(commonLabels.ERROR_WHILE_GENERATING_OTP);
                      return
              }
              let response = await API.post(OTPS, request);
              let emailOTPResponse;
              let mobileOTPResponse;
              let userNameOTPResponse;
              if(response!=null)
            {
                dispatch(hideLoader());
            }
              if (response && response.data) {
                  switch (response.data.type) {
                      case "email":
                          emailOTPResponse = {
                              id: response.data.id,
                              type: response.data.type
                          }
                          dispatch({
                              payload: emailOTPResponse,
                              type: SET_GET_EMAIL_OTP_RESPONSE
                          });
                          dispatch({
                            payload:response.data,
                            type : GET_OTP_DATA_EMAIL
                        })
                          if (otpDetails.isForgotPassword) {
                              mobileOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              userNameOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              dispatch({
                                  payload: mobileOTPResponse,
                                  type: SET_GET_MOBILE_OTP_RESPONSE
                              });
                              dispatch({
                                  payload: userNameOTPResponse,
                                  type: SET_GET_USERNAME_OTP_RESPONSE
                              });
                          }
                          toastr.success(commonLabels.OTP_SENT_NOTE);
                          break;
                      case "mobile":
                          mobileOTPResponse = {
                              id: response.data.id,
                              type: response.data.type
                          }
    
                          dispatch({
                              payload: mobileOTPResponse,
                              type: SET_GET_MOBILE_OTP_RESPONSE
                          });
                          dispatch({
                            payload:response.data,
                            type:GET_OTP_DATA_MOBILE
                        })
                          if (otpDetails.isForgotPassword) {
                              emailOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              userNameOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              dispatch({
                                  payload: emailOTPResponse,
                                  type: SET_GET_EMAIL_OTP_RESPONSE
                              });
                              dispatch({
                                  payload: userNameOTPResponse,
                                  type: SET_GET_USERNAME_OTP_RESPONSE
                              });
                          }
                          toastr.success(commonLabels.OTP_SENT_NOTE);
                          break;
                      case "username":
                          userNameOTPResponse = {
                              id: response.data.id,
                              type: response.data.type
                          }
                          dispatch({
                              payload: userNameOTPResponse,
                              type: SET_GET_USERNAME_OTP_RESPONSE
                          });
                          dispatch({
                            payload:response.data,
                            type:GET_OTP_DATA_USERNAME
                        })
                          if (otpDetails.isForgotPassword) {
                              emailOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              mobileOTPResponse = {
                                  id: "",
                                  type: ""
                              }
                              dispatch({
                                  payload: emailOTPResponse,
                                  type: SET_GET_EMAIL_OTP_RESPONSE
                              });
                              dispatch({
                                  payload: mobileOTPResponse,
                                  type: SET_GET_MOBILE_OTP_RESPONSE
                              });
                          }
                          toastr.success(commonLabels.OTP_SENT_NOTE);
                          break;
                      default:
                          toastr.info(commonLabels.ERROR_WHILE_GENERATING_OTP);
                          return
                  }
                  dispatch({
                      payload: false,
                      type: SET_VERIFY_OTP_FLAG_LOGIN
                  })
                  dispatch({
                      payload: false,
                      type: SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD
                 })
                }
            } catch (error) {
                dispatch(hideLoader());
               let errorMessage = (error.response && error.response.data) ?
                   error.response.data.message : error.message
              toastr.error(commonLabels.GET_OTP_ERROR, errorMessage);
            }
       }
};

export const generateOTP = (otpDetails) => {
    return async (dispatch) => {
        dispatch({
            payload: false,
            type: SET_IS_MOBILE_NO_ALREADY_EXIST_FLG
        });
        dispatch({
            payload: false,
            type: SET_IS_EMAIL_ID_ALREADY_EXIST_FLG
        });
        dispatch(showLoader());
        try {
            let request;
            switch (otpDetails.type) {
                case "email":
                    if (otpDetails.email === "") {
                        dispatch(hideLoader());
                        toastr.info(commonLabels.ENTER_VALID_EMAIL_ID);
                        return
                    }
                    request = {
                        "email": otpDetails.email,
                        "mobile": "",
                        "type": otpDetails.type,
                        "otpFor": otpDetails.otpFor
                    }
                    break;
                case "mobile":
                    if (otpDetails.mobile === "") {
                        dispatch(hideLoader());
                        toastr.info(commonLabels.ENTER_VALID_MOBILE_NUMBER);
                        return
                    }
                    request = {
                        "email": "",
                        "mobile": otpDetails.mobile,
                        "type": otpDetails.type,
                        "otpFor": otpDetails.otpFor
                    }
                    break;
                default:
                    dispatch(hideLoader());
                    toastr.info(commonLabels.ERROR_WHILE_GENERATING_OTP);
                    return
            }
            let response = await API.post(OTPS, request);
            let emailOTPResponse;
            let mobileOTPResponse;
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                switch (response.data.type) {
                    case "email":
                        emailOTPResponse = {
                            id: response.data.id,
                            type: response.data.type
                        }
                        dispatch({
                            payload: emailOTPResponse,
                            type: SET_GET_EMAIL_OTP_RESPONSE
                        });
                        dispatch({
                            payload:response.data,
                            type : GET_OTP_DATA_EMAIL
                        })
                        if (otpDetails.isForgotPassword) {
                            mobileOTPResponse = {
                                id: "",
                                type: ""
                            }
                            dispatch({
                                payload: mobileOTPResponse,
                                type: SET_GET_MOBILE_OTP_RESPONSE
                            });
                        }
                        if (otpDetails.getOtpOnlyFor === "email") {
                            toastr.success(commonLabels.OTP_SENT_ON_REGISTERED_EMAIL);
                        }
                        else {
                            toastr.success(commonLabels.OTP_SENT_NOTE);
                        }
                        break;
                    case "mobile":
                        mobileOTPResponse = {
                            id: response.data.id,
                            type: response.data.type
                        }
                        dispatch({
                            payload: mobileOTPResponse,
                            type: SET_GET_MOBILE_OTP_RESPONSE
                        });
                        dispatch({
                            payload:response.data,
                            type:GET_OTP_DATA_MOBILE
                        })
                        if (otpDetails.isForgotPassword) {
                            emailOTPResponse = {
                                id: "",
                                type: ""
                            }
                            dispatch({
                                payload: emailOTPResponse,
                                type: SET_GET_EMAIL_OTP_RESPONSE
                            });
                        }
                        toastr.success(commonLabels.OTP_SENT_ON_REGISTERED_MOBILE);
                        break;
                    default:
                        toastr.info(commonLabels.ERROR_WHILE_GENERATING_OTP);
                        return
                }
                dispatch({
                    payload: false,
                    type: SET_VERIFY_OTP_FLAG_LOGIN
                })
                dispatch({
                    payload: false,
                    type: SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD
                })
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message;
                if(errorMessage === commonLabels.ENTERED_MOBILE_NO_IS_ALREADY_EXIST && otpDetails.type === "mobile"){
                    dispatch({
                        payload: true,
                        type: SET_IS_MOBILE_NO_ALREADY_EXIST_FLG
                    })
                }else if(errorMessage === commonLabels.ENTERED_EMAIL_ID_IS_ALREADY_EXIST && otpDetails.type === "email"){
                    dispatch({
                        payload: true,
                        type: SET_IS_EMAIL_ID_ALREADY_EXIST_FLG
                    })
                }
            toastr.error(commonLabels.GET_OTP_ERROR, errorMessage);
        }
    }
};

export const verificationOfOTP = (reqBody, screen) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try{
        let response = await API.post(VERIFYOTP, reqBody);
        if(response!=null)
            {
                dispatch(hideLoader());
            }
        if (response.data) {
            toastr.success(registrationLabels.OTP_VERIFICATION_SUCCESSFUL);
        } else {
            toastr.error(registrationLabels.OTP_VERIFICATION_UNSUCCESSFUL);
        }
        if (screen === 'login') {
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_LOGIN
            })
        }
        if (screen === 'forgotPassword') {
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD
            })
        }
        if(screen === 'registration-email'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL
            })
        }
        if(screen === 'registration-mobileNumber'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE
            })
        }
        if(screen === 'home-email'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_FORGOT_EMAIL
            })
        }
        if(screen === 'home-mobileNumber'){
            dispatch({
                payload: response.data,
                type: SET_VERIFY_OTP_FLAG_FORGOT_MOBILE
            })
        }
    }catch (error) {
                dispatch(hideLoader());
        if (error && error.response) {
            let errorMessage = (error && error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    }
    }
};

export const enableOTPButton = (screen) => {
    return (dispatch) => {
        if (screen === 'login') {
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_LOGIN
            })
        }
        if (screen === 'forgotPassword') {
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD
            })
        }
        if(screen === 'registration-email'){
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL
            })
        }
        if(screen === 'registration-mobileNumber'){
            dispatch({
                payload: false,
                type: SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE
            })
        }
        if(screen === 'home-email'){
            dispatch({
                payload : false,
                type : SET_VERIFY_OTP_FLAG_FORGOT_EMAIL
            })
        }
        if(screen ==='home-mobileNumber'){
            dispatch({
                payload : false,
                type : SET_VERIFY_OTP_FLAG_FORGOT_MOBILE
            })
        }
    }
};

export const showLoader = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADER
        })
    }
};

export const hideLoader = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_LOADER
        })
    }
};

export const checkHas2018RecoLetter = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get("/oas/api/v1/has2018recoletter",
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        dispatch({
            type: 'set_has_2018_reco_letter_config',
            payload: response.data,
        });
    }
};

export const download2018RecoLetter = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get("/oas/api/v1/download2018recoletter",
                { 
                    headers: { 'Authorization': `Bearer ${token}` },
                    responseType: 'blob',
                }
            );
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            JSON.parse(await error.response.data.text()).message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(response.data, `Reco_Letter.pdf`);
    }
};

export const getGuidelineLinks = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.get(GUIDELINELINKS);
                dispatch({
                    type:SET_GUIDELINE_LINKS,
                    payload:response.data
                 }) 
                 if(response!=null)
            {
                dispatch(hideLoader());
            }            
        } catch (error) {
                dispatch(hideLoader());
            toastr.error(commonLabels.ERROR_WHILE_SHOWING_GUIDELINE_LINKS, error.message);
        }
    }
};

export const checkModuleEnableDisable = ( moduleName, flg ) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try{
            if(flg){
                dispatch({
                    type: SET_API_CALL_FOR_MODULE,
                    payload: true
                })
            }
            let response = await API.get( MODULE_CHECK + `/${moduleName}` )
            if(response.data){
                dispatch({
                    type : SET_MODULE_ENABLE_DISABLE,
                    payload : response.data
                })
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        }catch(error){
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const easebuzzcallback = ( method,request='') => {
    return async (dispatch) => {
        dispatch(showLoader());
        try{
            let response = await API.post(EASEBUZZ_CALLBACK+`/${method}`, request);
            if (response.data) {
                toastr.success(commonLabels.PAYMENT_SUCCESSFULLY_COMPLETED);
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        }catch(error){
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
    }
};