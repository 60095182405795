/* eslint-disable */
import axios from 'axios';
import CRC32 from 'crc-32';
import { OAS_API_BASE_URL as baseUrlArr } from "../configuration";
import { decryptData, encryptData } from '../common/actions/commonActions';
import { SECRET_KEY } from '../configuration';

let baseURL = "api.mpsconline.gov.in";
const currentDomain = window.location.hostname;

baseUrlArr && baseUrlArr.length > 0 && baseUrlArr.filter((e) => {
    if (currentDomain === e.key) {
        baseURL = e.value;
        axios.defaults.baseURL = e.baseUrl;
    }
});

export function generateCRC() {
    const dataWithSecret = SECRET_KEY
    const localCrcValue = CRC32.str(dataWithSecret).toString(16);
    return localCrcValue
}
const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: "json"
});
axiosInstance.interceptors.request.use(
    (config) => {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session?.jwtResponse?.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers['Access-Control-Allow-School'] = generateCRC();


        if (config.headers['Content-Type'] === 'multipart/form-data') {
            return config;
        }
        
        if (config.method !== 'get' && config.data && !config.data.encryptedReqBody) {
            config.data = { encryptedReqBody: encryptData(JSON.stringify(config.data)) };
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor for decryption od data 
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data) {
            response.data = JSON.parse(decryptData(response.data));
        }
        return response;
    },
    (error) => {
        if (error.response) {
            console.error('Error response:', error.response);
        }
        return Promise.reject(error);
    }
);
export const axiosInstanceWithoutEncrDecr = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-School' : generateCRC()
    },
    responseType: "json"
});
export default axiosInstance;